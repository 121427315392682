<template>  
        <div class="absolute top-1/3 w-full z-0 font-black text-white uppercase font-blackMango text-center">
            
            
            <p class="text-hero lg:text-titleSubPage md:text-titleSubPage tracking-wide font-black" > {{ $t('about.LA.gross_title') }}</p>
         
            
            
            <p class="tracking-widest font-bold font-blackMango text-baselg lg:text-lg md:text-lg" > {{ $t('about.LA.title') }}</p>

            <div class="flex inline text-center justify-center items-center">
                <div class="border-b border-lightgray flex-none px-2 md:px-8 flex mr-4 mt-4"></div>
                <p class="tracking-buttonSearch font-light font-blackMango text-sm lg:text-baselg md:text-baselg mt-4" > {{ $t('about.LA.date') }}</p>
                <div class="border-b border-lightgray flex-none px-2 md:px-8 flex ml-4 mt-4"></div>
            </div>
        </div>
</template>

<script>
export default {
    name: "CoverThisIsLa",

    
}
</script>

