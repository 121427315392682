<template>
    <div class="principal_watermark_container cursor-pointer" @click="$emit('click')">
        <div class="watermark">
            <img 
                src="/img/watermark/watermarker.png"
                lazy-src="/img/watermark/watermarker.png"
            />
        </div>
        <img
            :src="imageSrc"
            :class="myclass"
        />
    </div>
</template>
<style>
.principal_watermark_container{
    position: relative;
    height: 100%;
    width: 100%;
}
.watermark
{
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 10;
}
.watermark img{
    width: 100%;
    height: 100%;
    height: auto; 
    opacity: 1;
}
</style>
<script>
export default {
    name: 'WatermarkImage',
    props:{
        imageSrc: String,
        myheight: {
            Type: String,
            default: 'auto'
        },
        mywidth: {
            Type: String,
            default: 'auto'
        },
        myclass: {
            Type: String,
            default: 'auto'
        }
    }
}
</script>
