import axios from 'axios'
//import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
  getDistricts(params) {
    return axios.get('/districts', {
      params
    })
  },
  getDistrict(params) {
    return axios.get(`/districts/${params}`, {
      params
    })
  },
}
