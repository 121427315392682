import * as types from '@/store/mutation-types'
import api from '@/services/api/general/general'
import { buildSuccess, handleError } from '@/utils/utils.js'


const getters = {
  keys: (state) => state.keys,
  totalKeys: (state) => state.totalKeys
}

const actions = {
  getWhoWeAreDescription({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getWhoWeAreDescription(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.WHO_WE_ARE, response.data.whoWeAre)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getAllAgents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .getAllAgents(payload)
          .then((response) => {
            if (response.status === 200) {
              commit(types.ALL_AGENTS, response.data.agents)
              resolve()
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    getServiceDescription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .getServiceDescription(payload)
          .then((response) => {
            if (response.status === 200) {
              commit(types.SERVICES_DESCRIPTION, response.data.service_description)
              resolve()
            }
          })
          .catch((error) => {
            handleError(error, commit, reject)
          })
      })
    },
    saveHomeMessage({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .saveHomeMessage(payload)
            .then((response) => {
              if (response.status === 200) {
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve
                )
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      bookVisitMessage({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .bookVisitMessage(payload)
            .then((response) => {
              if (response.status === 200) {
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve
                )
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      vineyardContactMessage({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .vineyardContactMessage(payload)
            .then((response) => {
              if (response.status === 200) {
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve
                )
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      workWitheMeContactMessage({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .workWitheMeContactMessage(payload)
            .then((response) => {
              if (response.status === 200) {
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve
                )
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },


      
      
      sellWithUsRequest({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .sellWithUsRequest(payload)
            .then((response) => {
              if (response.status === 200) {
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve
                )
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      getAllServices({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .getAllServices(payload)
            .then((response) => {
              if (response.status === 200) {
                commit(types.SERVICES, response.data.services)
                resolve()
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      getServiceDescriptionFront({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .getServiceDescriptionFront(payload)
            .then((response) => {
              if (response.status === 200) {
                commit(types.SERVICE_DESCRIPTION, response.data.serviceDescription)
                resolve()
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      getAllMapMarkers({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .getAllMapMarkers(payload)
            .then((response) => {
              if (response.status === 200) {
                commit(types.MARKERS, response.data.markers)
                resolve()
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      getAllMapMarkersById({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .getAllMapMarkersById(payload)
            .then((response) => {
              if (response.status === 200) {
                commit(types.MARKERS, response.data.markers)
                resolve()
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },
      saveNewsletterEmail({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .saveNewsletterEmail(payload)
            .then((response) => {
              if (response.status === 201) {
                buildSuccess(
                  {
                    msg: 'common.SAVED_SUCCESSFULLY'
                  },
                  commit,
                  resolve(response)
                )
              }
            })
            .catch((error) => {
              handleError(error, commit, reject)
            })
        })
      },

      
}

const mutations = {
  [types.KEYS](state, keys) {
    state.keys = keys
  },
  [types.TOTAL_KEYS](state, value) {
    state.totalKeys = value
  },
  [types.MARKERS](state, value) {
    state.markers = value
  },
  [types.SERVICES](state, value) {
    state.services = value
  },
  [types.SERVICE_DESCRIPTION](state, value) {
    state.serviceDescription = value
  },
  [types.SHOW_LOADING](state, value) {
    state.showLoading = value
  },
  [types.SUCCESS](state, value) {
    state.success = value
  },
  [types.ERROR](state, value) {
    state.error = value
  },
  [types.SERVICES_DESCRIPTION](state, value) {
    state.service_description = value
  },  
  [types.ALL_AGENTS](state, value) {
    state.all_agents = value
  }, 
  [types.WHO_WE_ARE](state, value) {
    state.who_we_are = value
  }, 
  key(state, data) {
    state.key[data.k] = data
  }
}

const state = {
  who_we_are: [],
  all_agents: [],
  service_description: [],
  keys: [],
  totalKeys: 0,
  key: {
    features: { data: [] },
    types: { data: [] },
    listing: { data: [] },
    lifestyle: { data: [] }
  },
  markers: [],
  services: [],
  serviceDescription: []
}

export default {
  state,
  getters,
  actions,
  mutations
}

