import { render, staticRenderFns } from "./AboutWhoWeAre.vue?vue&type=template&id=963e4b44&"
import script from "./AboutWhoWeAre.vue?vue&type=script&lang=js&"
export * from "./AboutWhoWeAre.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports