function currency(n) {
  n = parseFloat(n)
  n = n.toLocaleString('en-' + new String(this.$i18n.locale).toUpperCase(), { style: 'currency', currency: 'EUR' });


  // This is for Ios support
  // The /c/g with the replace function instead of replaceAll
  // Check more: https://stackoverflow.com/questions/2390789/how-to-replace-all-dots-in-a-string-using-javascript

  // Only the dot needs to have the inverted slash because its a special char (explained in the link above)
  n = n.replace(/\./g, '!')
  n = n.replace(/,/g, '.')
  n = n.replace(/!/g, ',')
  n = n.split(',00')[0]
  n = n.split('€')[1]
  return n + ' €'
}

export default currency