import * as types from '@/store/mutation-types'
import api from '@/services/api/properties/properties'
//import { buildSuccess, handleError } from '@/utils/utils.js'
import { handleError } from '@/utils/utils.js'
//import router from '@/router'

const getters = {
  properties: (state) => state.properties,
  properties_home: (state) => state.properties_home,
  block: (state) => state.block
}

const actions = {
  getProperties({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getProperties(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTIES, response.data.properties)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  homepageGetProperties({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .homepageGetProperties(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTIES_HOME, response.data.properties)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getHomepageCollectionPrivee({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getHomepageCollectionPrivee(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.COLLECTION, response.data.properties)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },  
  getPropertyBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPropertyBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTY_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },  
  getMediaBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getMediaBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.MEDIA_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }, 
  getAttachmentBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getAttachmentBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ATTACHMENT_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }, 
  getPropertiesFromCondominium({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPropertiesFromCondominium(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PROPERTIES, response.data.properties)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  
  
}

const mutations = {
  [types.PROPERTIES_HOME](state, properties_home) {
    state.properties_home = properties_home
  },
  [types.PROPERTIES](state, properties) {
    state.properties = properties
  },
  [types.COLLECTION](state, properties) {
    state.collection = properties
  },
  [types.TAGS](state, tags) {
    state.tags = tags
  },
  [types.PROPERTY_BLOCK](state, block) {
    state.block = block
  },  
  [types.MEDIA_BLOCK](state, block) {
    state.medias = block
  },
  [types.ATTACHMENT_BLOCK](state, block) {
    state.attachments = block
  },
  [types.AGENT](state, list) {
    state.agent = list
  },
  [types.TOTAL_AGENTS](state, value) {
    state.totalAgents = value
  }
}

const state = {
  properties_home: [],
  properties: [],
  collection: [],
  block: [],
  medias: [],
  attachments: [],
  totalAgents: 0,
  agent: [],
  tags: []
}

export default {
  state,
  getters,
  actions,
  mutations
}