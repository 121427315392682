<template>
    <div class="px-20 py-10 lg:py-20 sm:py-10 flex flex-col h-screen-85 content-center justify-center" v-if="project">
        
        <div class="border-b">
            <!-- city -->
            <p class="font-blackMango tracking-wide text-base text-center" v-if="$processLanguage(project.location)!=''">{{ $processLanguage(project.location) }}<span class="text-bronze text-md"> &#8226;</span> </p>
             <!-- title -->
            <p class="mb-2 font-blackMango text-center text-lg font-bold tracking-wider">{{ $processLanguage(project.title) }} </p>
            <p class="mb-3 font-blackMango text-sm text-center font-bold tracking-wider">{{ $processLanguage(project.slogan) }} </p>
        </div>

        <!-- description -->
        <div class=" max-h-3/5 ">
        <div class="h-full overflow-auto hide-scrollbar scrollable-content">
            <p class="mt-3 text-justify text-sm leading-relaxed">
                {{ $processLanguage(project.description) }}
            </p>
            </div>
        </div>

            <ButtonHomepage @click="generateLink" bgColor="black" class="md:flex hidden mt-6" :text="$t('buttons.discover')" />
    </div>
</template>

<script>
    import ButtonHomepage from "@/components/buttons/ButtonHomepage.vue";

    export default {
        name: "CollectionProjectText",
        props: ["project","currentProject","projectListLength"],
        components: { ButtonHomepage },
        methods: {
            toNextProject() {
                this.$emit('toNextProject');
            },
            toPreviousProject() {
                this.$emit('toPreviousProject');
            },
            generateLink(){
                this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: this.project.id }})
            },
        }
    }
</script>