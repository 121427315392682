<template>
    <div class="w-full h-full md:h-screen overscroll-y-none">
        <div  class="overlay-districts"> 
            <img src="/img/thisisla/cover.jpg" class="h-full w-full object-cover absolute" >
        </div>
        
        <CoverThisIsLa class="fixed z-auto" />
        
        <FullPageModal id="thisisla_fullpagemodal">
            <AboutWhoWeAre/>
            <AboutServicesSegments />
            <div class=" hidden md:block lg:mt-32">
              <div class="w-full h-screen-50 lg:mb-24 lg:mt-24">
                  <div class="w-full h-full flex justify-center">
                      <img class="mt-8 w-1/2 lg:w-1/6 h-full left-1/4 object-cover rounded-full border-14 border-black sm:h-32 sm:border-14 lg:w-1/6 lg:h-full lg:border-14" 
                          src="/img/sellwithus/sellwithus.jpg"
                      >
                  </div>
              </div>
            </div>
            <div v-if="false">
              <div class="relative" v-if="getAgentsArray.length != 0">
                <swiper :options="swiperOption" ref="swiperThisisLA" class="swiper pointer-events-none">
                  <swiper-slide v-for="item in getAgentsArray" :key="item.id">
                    <AgentLA @open-modal="openWorkModal" :selectedAgent="item"/>
                  </swiper-slide>
                </swiper>
                <div class="font-bold font-blackMango flex space-x-4 text-md p-4 swiperThisisLA-controls">
                    <button class="btn w-100 text-center" @click="toPrevious()">
                        <inline-svg  v-if="isNotBeginning" class="transform rotate-180" fill="black" src="/svg/arrow/arrow.svg" />
                    </button> 
                    <button class="btn w-100 text-center" @click="toNext()">
                        <inline-svg v-if="isNotEnd" fill="black" src="/svg/arrow/arrow.svg" />
                    </button> 
                </div>
              </div>
            </div>
            <AboutVineyardsContact @thankYouMessage="thankyou" />
            <AboutVLHServices v-if="false"/>
            <Footer properties class="h-full md:h-full"/>
        </FullPageModal>
        
        <FormModal v-show="workModal" @close="closeWorkModal" :noclose="thankyouModal">
            <!-- begin::modal body -->
            <template v-slot:body>
                <WorkWithMe @thankYouMessage="thankyou" v-show="!thankyouModal"/>
                <ThankYouModal v-show="thankyouModal" @close="closeWorkModal" />
            </template>
            <!-- end::modal body -->
        </FormModal>
    </div>
</template>
<style scoped>
.swiperThisisLA-controls{
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  justify-content: space-around;
  align-items: flex-end;
  z-index: 99;
}
.swiperThisisLA-controls button svg {
  height: 35px;
  width: 44px;
}
</style>
<script>
    import CoverThisIsLa from "@/components/ThisIsLA/CoverThisIsLa";
    import AboutServicesSegments from "@/components/ThisIsLA/AboutServicesSegments";
    import AboutWhoWeAre from "@/components/ThisIsLA/AboutWhoWeAre";
    import AgentLA from "@/components/ThisIsLA/AgentLA";
    import AboutVLHServices from "@/components/ThisIsLA/AboutVLHServices";
    import AboutVineyardsContact from "@/components/ThisIsLA/AboutVineyardsContact";
    import Footer from '@/layout/footer/FooterExtended'
    import FullPageModal from '@/components/modal/FullPageModal';
    import FormModal from '@/components/modal/FormModal'
    import ThankYouModal from '@/components/modal/ThankYouModal'
    import WorkWithMe from '@/components/contact/WorkWithMe'
    // Execution code
    import mixin from '@/models/agents/AgentsModel'
    import { AgentForm } from '@/store/structs/agent'
    import {
        Swiper,
        SwiperSlide
    } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'
export default {
    name: "About",
    components: {
        CoverThisIsLa,
        AboutServicesSegments,
        AboutWhoWeAre,
        AgentLA,
        AboutVLHServices,
        AboutVineyardsContact,
        Footer,
        FullPageModal,
        FormModal,
        WorkWithMe,
        ThankYouModal,
        Swiper,
        SwiperSlide,
    },
    mixins: [mixin],
    data(){
        return{
            workModal: false,
            thankyouModal: false,
            thankYouName: '',
            // Array containing all agents
            agentsArray: [],
            // Object of selected Agent
            selectedAgent: null,
            swiper: undefined,
            swiperOption: {
                loop: false,
                loopedSlides: 2,
                speed:600,
                followFinger:false
            },
        }
    },
    async created () {
        this.$emit('show-header')
        await this.getAgents()
        
        const retrievedData = this.$store.state.agentsModule.list;
        const totalAgents = this.$store.state.agentsModule.totalAgents;

        
        if(totalAgents){
          const dataKeys = Object.keys(retrievedData)
          for await (const key of dataKeys){
            
            await this.getMyAgent(retrievedData[key].id)
            let agentDataStructure = new AgentForm(this.$store.state.agentsModule.agent)
            this.agentsArray.push(agentDataStructure)
          }
        }
        this.swiper = this.$refs.swiperThisisLA.$swiper;
        
    },
    methods:{
        toNext() {
          this.swiper?.slideNext();
        },
        toPrevious() {
          this.swiper?.slidePrev();
        },
        thankyou(name){
          this.thankYouName = name;
          this.thankyouModal = true;
          this.workModal = true
        },
        closeWorkModal(){
          this.workModal = false
        },
        openWorkModal(){
          this.thankyouModal = false;
          this.workModal = true
        },
    },
    computed:{
        getAgentsArray(){
          return this.agentsArray
        },
        getSelectedAgent(){
          return this.selectedAgent
        },
        isNotBeginning(){
          return this.swiper ? !this.swiper?.isBeginning : false;
        },
        isNotEnd(){
          return this.swiper ? !this.swiper?.isEnd : true;
        }
    }
}
</script>