import axios from 'axios'
//import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
  getCondominiums(params) {
    return axios.get('/condominiums', {
      params
    })
  },
  getHomepageCondominiums(params) {
    return axios.get('/front/condominiums', {
      params
    })
  },
  getCondominiumBlock(params) {
    return axios.get(`/front/condominiums/${params.id}/${params.block}`, {
      params
    })
  },
  getCondominiumMediaBlock(params) {
    return axios.get(`/condominiums/${params.id}/${params.block}`, {
      params
    })
  }, 
  getCondominiumAttachmentBlock(params) {
    return axios.get(`/condominiums/${params.id}/${params.block}`, {
      params
    })
  },

  

  
  
  

}
