<template>
    <div :class="'text-' + color"  class="flex flex-initial items-end">
        <inline-svg class="fill-current" height="1.2rem" width="1.2rem" :src="img" />
        <span>
            <p class="font-medium leading-none text-base ml-1 bottom-0">{{number + extra}}</p>
        </span>
    </div>
</template>
<script>
    export default {
        name: "PropertyDetailsSmall",
        props:{
            number:{
                type: String,
            },
            img:{
                type: String,
            },
            color:{
                type: String,
            },
            extra:{
                type: String,
                default: ''
            }
        }
        
    }
</script>