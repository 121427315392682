<template>
    <div class="mt-2 lg:mt-14 lg:mt-16 mb-8 lg:mb-0" v-if="information != null">
        <div v-if="false" class="w-1/2 lg:w-10/12 inset-x-0 m-auto flex items-center justify-center">
            <inline-svg width="8rem" height="auto" class="w-full lg:w-1/3" src="/svg/logos/logo_black.svg" />
        </div>



        <div class="hidden lg:block lg:w-10/12 inset-x-0 m-auto flex items-center justify-center">
            <img class="w-full" style="margin-top: -15%; margin-bottom: -7%" src="/new_logos/2022-LA-Agency-Logotipo_Gold.png">
        </div>

        <div class="lg:hidden w-full inset-x-0 m-auto flex items-center justify-center">
            <img class="w-full object-cover" style="margin-top: -15%; transform: scale(2);" src="/new_logos/2022-LA-Agency-Logotipo_Gold.png">
        </div>



        <div class="w-10/12 inset-x-0 m-auto flex items-center justify-center">
            <p class="w-full uppercase font-blackMango text-xl font-bold tracking-widest text-center">{{$t('about.LAWhoWeAre.title')}}</p>
        </div>
        <div class="w-10/12 lg:w-1/3 mt-4 lg:mt-8 inset-x-0 m-auto flex items-center justify-center">
            <p class="w-full text-base text-darkgray font-normal text-justify"  style="white-space: pre-line">{{$processLanguage(information)}}</p>
        </div>        
    </div>
</template>
<script>
import GeneralMixin from '@/models/general/GeneralModel'
export default {
    name: "AboutWhoWeAre",
    mixins:[GeneralMixin],
    data(){
        return{
            information: null
        }
    },
    async created(){
        await this.getWhoWeAreDescription()
        this.information = this.$store.state.generalModule.who_we_are.description
    }
    //getWhoWeAreDescription

}
</script>