function isDistrict(value) {
    const districts = [
        'Viana do Castelo',
        'Braga',
        'Vila Real',
        'Bragança',
        'Porto',
        'Aveiro',
        'Viseu',
        'Guarda',
        'Coimbra',
        'Castelo Branco',
        'Leiria',
        'Santarém',
        'Portalegre',
        'Lisboa',
        'Évora',
        'Setúbal',
        'Beja',
        'Faro'
    ]

    return districts.includes(value)
    
  }
  
  export default isDistrict