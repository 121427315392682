<template>
    <div class="flex flex-col h-screen-75 md:h-screen-50 content-center justify-center items-center relative" v-if="project">
                <img class="object-cover absolute w-full h-screen-75 md:h-screen-50  " 
                :src="$mediaFile('properties', project.id , project.media.cover)"
                :lazy-src="$mediaFile('properties', project.id , project.media.cover)"
                alt="project">


        <div class="z-20 px-10 text-center text-white">
            <!-- city -->
            <p class="mb-2 font-blackMango tracking-wide text-md">{{ $processLanguage(project.information.location.district) }}<span class="text-bronze text-md"> &#8226;</span> </p>
             <!-- title -->
            <p class="mb-2 font-blackMango text-xl font-bold tracking-wider">{{ $processLanguage(project.information.title) }} </p>
            <p class="font-blackMango text-base font-bold tracking-wider">{{ $processLanguage(project.information.slogan) }} </p>
        </div>
    <!-- begin::overlay -->
        <div class="absolute overlay-cover h-full w-full">
        </div>
        <!-- end::overlay-->
         <!-- button -->
        <ButtonHomepage @click="generateLink" bgColor="bronze" class="z-40 mt-6" :text="$t('buttons.discover')" />
         
       <!-- begin::navigation mobile -->
        <div class="absolute mt-auto left-2 md:left-3 h-full z-30 flex items-center text-white">
            <button @click="toPreviousProject()">
                <inline-svg class="transform rotate-180 fill-current w-4 h-4 md:w-7 md:h-7"  src="/svg/arrow/arrow.svg" />
            </button>
        </div>
        <div class="absolute right-2 md:right-3 mt-auto h-full z-30  flex items-center text-white">
            <button @click="toNextProject()">
                <inline-svg class="fill-current w-4 h-4 md:w-7 md:h-7 " src="/svg/arrow/arrow.svg" />
            </button>
        </div>
        <!-- end::navigation mobile -->
    </div>
</template>

<script>
    import ButtonHomepage from "@/components/buttons/ButtonHomepage.vue";

    export default {
        name: "CollectionMobile",
        props: ["project"],
        components: {
            ButtonHomepage
        },
        methods: {
            toNextProject() {
                this.$emit('toNextProject');
            },
            toPreviousProject() {
                this.$emit('toPreviousProject');
            },
            generateLink(){
                this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: this.project.id }})
            },
        }
    }
</script>