<template>
    <div class="lg:mt-32">
        <div class="w-10/12 inset-x-0 m-auto lg:flex items-center relative">
            <div class="text-lg font-black w-full lg:w-2/5 inset-x-0 m-auto mt-0">
                
                <p class="w-full uppercase font-blackMango text-lg tracking-widest text-left" id="left-text-title">{{$t('about.LAServices.title')}}</p>
                <div v-if="getComputedServiceDescription">
                    <p class="text-justify w-full text-base text-darkgray font-normal mt-4" id="left-text-default" style="white-space: pre-line">
                        {{ $processLanguage(getComputedServiceDescription) }}
                    </p>
                </div>               
            </div>
            <div class="lg:w-1/2 mx-auto cursor-pointer services-list">
                <div @click="openService(item.i18n.title)" v-for="(item, key) in getComputedServices" :key="item.id">
                    <div class="flex flex-wrap border-b border-lightgray cursor-pointer">
                        <div class="w-full m-4 flex cursor-pointer">
                            <label class="mt-4 relative text-black mr-4 lg:mr-10 text-gray text-base font-bold cursor-pointer font-blackMango leading-none" v-if="key!=$t('about.LAServices.services_list').length-1">{{key + 1}}</label>
                            <label class="mt-4 text-black mr-4 lg:mr-10 text-opacity-50 text-base font-bold cursor-pointer font-blackMango leading-none" v-else>{{0}}</label>
                            <label class="mt-4 w-full text-baselg tracking-widest font-bold uppercase font-blackMango cursor-pointer leading-none">
                                {{ $processLanguage(item.i18n.title) }}
                            </label>    
                            <div class="mt-2 lg:mt-0 text-right relative cursor-pointer">
                                <label :id="'label_'+$processLanguage(item.i18n.title)" class="text-black text-opacity-50 text-lg text-right cursor-pointer">+</label>
                            </div>                              
                        </div>
                        <div :id="'description_'+$processLanguage(item.i18n.title)" class="hidden w-11/12 ml-4 mr-4 lg:mr-0 cursor-pointer mb-4 sm:text-base text-justify font-normal service-description" :class="key==5 ? 'mb-80' : ''">
                            <div class="mt-0 absolute w-full lg:w-2/5 left-text hidden lg:block text-left">
                                <p class="w-full uppercase font-blackMango text-lg tracking-widest font-black">{{$t('about.LAServices.title')}}</p>
                                <p class="text-justify w-full text-base text-darkgray font-normal mt-4" style="white-space: pre-line">
                                    {{ $processLanguage(getComputedServiceDescription) }}
                                </p>
                            </div>
                            <span v-html="$processLanguage(item.i18n.description)"></span>
                        </div>              
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>
<style>
.services-list{
  margin-top:38px
}
.location-bullet{
  transform: translateY(-2px);
}
.service-description ul{
  list-style: initial;
  padding-left: 30px;
}
.service-description ul li::marker{
  color: var(--color-bronze);
}
.left-text{
    left:24px;
}
.left-text-title{
    left:24px;
}
</style>
<script>
import GeneralMixin from '@/models/general/GeneralModel'
import { Service, ServiceDescription, ServiceDescriptionForm } from '@/store/structs/services'
export default {
    name: "AboutServicesSegments",
    mixins:[GeneralMixin],
    data(){
        return{
            showDescriptionService: false,
            descriptionText: '',
            loadedData: false,
            lastOpened: '',
            service: ServiceDescriptionForm,
        }
    },
    async created(){
        try {
            const services_id = this.$route.query?.services;

            if(services_id && this.$store.state.generalModule.services.length){
              setTimeout(() => this.openServiceByQuery(services_id))
              return;
            }

            await this.getServiceDescriptionFrontPage()
            await this.getServices()
            await this.getServiceDescription()
            if(services_id){
              this.openServiceByQuery(services_id)
            }
            
        } catch (error) {
            console.log(error)
        }
    },
    methods:{
        openServiceByQuery(services_id){
          const services = this.getComputedServices;
          const service = services.find(item => item.id == services_id);
          service && this.openService(service.i18n.title, true);
        },
        openService(tag, scrollTo){
            tag = this.$processLanguage(tag)

            const label = document.getElementById('label_'+tag);

            const open = label?.textContent == '-';
            this.closeAllServices();
            if(label && !open){
              label.textContent = '-'
              const desc = document.getElementById('description_'+tag);
              desc.classList?.remove('hidden');
              if(window.innerWidth >= 1024){
                const leftText = document.getElementById('left-text-default');
                leftText.classList?.add('hidden');
                const leftTextTitle = document.getElementById('left-text-title');
                leftTextTitle.classList?.add('hidden');
              }
              

              if(scrollTo){
                const page = document.getElementById('thisisla_fullpagemodal').firstElementChild;
                //console.log(desc.offsetTop);
                if(window.innerWidth >= 1900){
                    page.scrollTo(0, desc.offsetTop + 1735);
                }
                else{
                    page.scrollTo(0, desc.offsetTop + 1620);
                }
                
              }
            }
            else{
                const leftText = document.getElementById('left-text-default');
                leftText.classList?.remove('hidden');
                const leftTextTitle = document.getElementById('left-text-title');
                leftTextTitle.classList?.remove('hidden');
            }
        },
        closeAllServices(){
            this.getComputedServices.forEach(element => {
                document.getElementById('description_'+this.$processLanguage(element.i18n.title)).classList?.add('hidden')
                document.getElementById('label_'+this.$processLanguage(element.i18n.title)).textContent = '+';
            });
        }
    },
    computed:{
        getComputedServices(){
            const retrievedData = this.$store.state.generalModule.services
            if(retrievedData == null)
                return []

            if(retrievedData.length == 0)
                return []
                
            return retrievedData.map(item =>
                new Service(item)
            )
        },
        getComputedServiceDescription(){
            const retrievedData = this.$store.state.generalModule?.serviceDescription
             if(retrievedData == null)
                return []

            if(retrievedData.length == 0)
                return []

            return new ServiceDescription(retrievedData[0])?.i18n?.description;
        }
    }
}
</script>

