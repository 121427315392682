<template>
    <button @click="onClick" :class="{ ['hover:bg-darkBronze'+' '+'bg-bronze']: bgColor==='bronze', ['hover:bg-bronze'+' '+'bg-black']: bgColor==='black' }" class="font-medium uppercase text-sm tracking-button inline-flex items-center justify-center  transition duration-500 ease-in-out text-white w-2/3 md:w-auto md:min-w-64 md:px-6 py-2 md:py-3">
        <span>{{ text }}</span>
    </button>
</template>

<script>
export default {
  name: "ButtonHomepage",
  props: {
    text: String,
    bgColor: String
  },
  methods:{
    onClick(){
      this.$emit('click')
    }
  }
}
</script>