import * as types from '@/store/mutation-types'
import api from '@/services/api/districts/districts'
import { handleError } from '@/utils/utils.js'
//import router from '@/router'

const getters = {
  districts: (state) => state.districts,
  district: (state) => state.district,
  totalDistricts: (state) => state.totalDistricts
}

const actions = {
  getDistricts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getDistricts(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.DISTRICTS, response.data.districts)
            commit(types.TOTAL_DISTRICTS, response.data.total)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getDistrict({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getDistrict(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.DISTRICT, response.data.district)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [types.DISTRICT](state, district) {
    state.district = district
  },
  [types.DISTRICTS](state, districts) {
    state.districts = districts
  },
  [types.TOTAL_DISTRICTS](state, value) {
    state.totalDistricts = value
  }
}

const state = {
  districts: [],
  totalDistricts: 0,
  district: [],
}

export default {
  state,
  getters,
  actions,
  mutations
}
