<template>
    <div class="bg-black pt-4 md:pt-8 lg:pt-12 text-white bottom-0 h-auto relative w-full overflow-hidden">
        <div class="mb-4 md:mb-10 mx-4 md:mx-16">
            <div class="grid grid-cols-6 gap-4 hidden lg:grid">
                <a v-for="dp in computeProperties" :key="dp.id"
                    @click="generateLink(dp.id)" 
                >
                <PropertyCardSmall
                    :id="dp.id"
                    :baths="new String(dp.kpis.baths)"
                    :rooms="new String(dp.kpis.beds)"
                    :garage="new String(dp.parking)"
                    :area="new String(dp.kpis.m2)"
                    :img="$mediaFile('properties', dp.id , dp.media.cover)"
                    :price="new String(dp.price)"
                    sizeImg="h-img-small-card"
                    :dev_id="dp.id"
                    color="white"
                />
                </a>
            </div>
            <div class="lg:hidden mt-5 md:mt-8 w-full block relative">
                <swiper :options="swiperOption" class="swiper" ref="swiperDevelopments">
                    <swiper-slide v-for="item in mobileComputeProperties" :key="item.id">
                        <a
                            @click="generateLink(item.id)" 
                        >
                            <PropertyCardBig 
                                :baths="new String(item.kpis.baths)" 
                                :rooms="new String(item.kpis.beds)" 
                                :garage="new String(item.parking)"
                                :area="new String(item.kpis.m2)"
                                location=""
                                :img="$mediaFile('properties', item.id , item.media.cover)"
                                :reference="item.reference"
                                cityAddress="" 
                                :isMobile="true"
                                :tag="item.tag" 
                                slogan=""
                                :price="new String($currency(item.price))" 
                                color="white" 
                        :dev_id="item.id"/>
                        </a>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 pt-4 pb-8 px-4 md:px-16">
            <!-- search by city -->
            <div class="col-span-1 hidden md:grid">
                <p class="text-base tracking-wider font-bold  font-blackMango uppercase">
                    {{$t('footer.extended.city.title')}}
                </p>
                <ul class="mt-2 text-sm cursor-pointer">
                    <li v-for="item in  $t('footer.extended.city.cities')" :key="item" @click="goToCity(item)" class="hover:text-bronze">{{item}}</li>
                </ul>
            </div>
            <!-- search by lifestyle -->
            <div class="col-span-1 hidden md:grid" v-if="false">
                <p class="text-base tracking-wider font-bold font-blackMango uppercase">
                    {{$t('footer.extended.lifestyle.title')}}
                </p>
                <ul class="mt-2 text-sm cursor-pointer">
                    <li v-for="(item, i) in  $t('footer.extended.lifestyle.types')" :key="item"  @click="listLifestyle(i)" class="hover:text-bronze">{{item}}</li>
                </ul>
            </div>
            <!-- search by type -->
            <div class="col-span-1 hidden md:grid">
                <p class="text-base tracking-wider font-bold font-blackMango uppercase text-sm">
                    {{$t('footer.extended.type.title')}}
                </p>
                <ul class="mt-2 text-sm cursor-pointer">
                    <li v-for="item in  $t('footer.extended.type.types')" :key="item" @click="segment(item)" class="hover:text-bronze">{{item}}</li>
                </ul>
            </div>

            <div class="col-span-1 hidden md:grid">
                <!-- contact info -->
                <p class="tracking-wider text-base font-bold font-blackMango uppercase">
                    {{$t('footer.extended.contact.title')}}
                </p>
                <p class=" mt-2 leading-relaxed text-sm">
                    {{$t('footer.extended.contact.adressLine1')}}<br>
                    {{$t('footer.extended.contact.adressLine2')}}<br>
                    {{$t('footer.extended.contact.phone1')}}<br>
                    {{$t('footer.extended.contact.phone2')}}<br>
                    <small>{{$t('footer.extended.contact.aviso')}}</small>
                </p>
            </div>
            <!-- newsletter -->
            <div class="col-span-2 hidden lg:block">
                <p class="text-base tracking-wider font-bold font-blackMango uppercase">
                    {{$t('footer.extended.newsletter.title')}}
                </p>
                <p class="mt-2 text-xs">
                    {{$t('footer.extended.newsletter.text')}}
                    <br>
                    {{$t('footer.extended.newsletter.text2')}}<a @click="openPrivacy" class="cursor-pointer hover:text-bronze">{{$t('footer.extended.newsletter.privacy_policy')}}</a>
                    <form id="newsletterForm" @submit.prevent="processNewsletterForm" class="mt-4 flex w-full bg-black newsletter-form">
                        <input id="newsletterForm-email" class="px-4 w-full py-2 mr-0 text-white text-sm"
                            type="email" :placeholder="$t('footer.extended.newsletter.placeholder')" @change="resetNewsletterStatus"/>
                        <button
                            form="newsletterForm"
                            type="submit"
                            class="md:px-8 px-8 w-1/2 bg-white text-black text-sm font-bold uppercase tracking-button whitespace-nowrap">{{$t('buttons.footerSubmit')}}</button>
                        <span class="hint">{{newsletterHint}}</span>
                    </form>
            </div>
        </div>
        <div
            class="md:flex hidden border-t-thin px-8 lg:px-16 uppercase py-3 tracking-wider m-0 border-white w-full bg-black text-white text-xs border-opacity-30 flex items-center">
            <div class="w-1/3">
                <p>Copyright© {{year}} EXCLUSIVEFAMOUS, Lda. All rights reserved.
                </p>
            </div>
            <!-- socials -->
            <div class="w-1/3">
               <SocialsLink color="white" />
            </div>
            <div class="w-1/3 text-right">
                <a target="_blank" href="https://www.livroreclamacoes.pt/inicio"
                    class="mr-2">{{$t('footer.extended.minimal.book')}} </a>
                <span>|</span>
                <router-link class="ml-2" :to="{ name: 'privacy', params: { lang: this.$i18n.locale }}">
                    {{ $t('footer.extended.minimal.privacy') }}
                </router-link>
            </div>
        </div>
        <div class="col-span-1 lg:hidden w-full p-4 pt-0 cursor-pointer accordion">
            <div @click="openAccordion('search')">
                <div class="flex flex-wrap cursor-pointer">
                    <div class="w-full flex cursor-pointer">
                        <label class="pt-1 w-full text-md tracking-widest font-medium text-white uppercase font-euclid cursor-pointer leading-5">
                            {{$t('footer.mobile.search')}}
                        </label>    
                        <div class="lg:mt-0 text-right relative cursor-pointer">
                            <label :id="'label_search'" class="text-white text-lg text-right cursor-pointer">></label>
                        </div>                              
                    </div>
                    <div :id="'description_search'" class="hidden w-full my-2 cursor-pointer accordion-description">
                        <label class="mt-4 w-full text-md tracking-widest font-medium text-white uppercase font-euclid cursor-pointer leading-5">
                            {{$t('footer.mobile.city')}}
                        </label>  
                        <ul class="text-md text-gray cursor-pointer mb-2 leading-4">
                            <li v-for="item in  $t('footer.extended.city.cities')" :key="item" @click="listCity(item)" class="hover:text-bronze">{{item}}</li>
                        </ul>
                        <label class="mt-4 w-full text-md tracking-widest font-medium text-white uppercase font-euclid cursor-pointer leading-5">
                            {{$t('footer.mobile.lifestyle')}}
                        </label>  
                        <ul class="text-md text-gray cursor-pointer mb-2 leading-4">
                            <li v-for="(item, i) in  $t('footer.extended.lifestyle.types')" :key="item"  @click="listLifestyle(i+1)" class="hover:text-bronze">{{item}}</li>
                        </ul>
                        <label class="mt-4 w-full text-md tracking-widest font-medium text-white uppercase font-euclid cursor-pointer leading-5">
                            {{$t('footer.mobile.listing')}}
                        </label>  
                        <ul class="text-md text-gray cursor-pointer leading-4">
                            <li v-for="item in  $t('footer.extended.type.types')" @click="segment(item)" :key="item" class="hover:text-bronze">{{item}}</li>
                        </ul>
                    </div>              
                </div>
            </div>
            <div @click="openAccordion('language')">
                <div class="flex flex-wrap cursor-pointer">
                    <div class="w-full flex cursor-pointer">
                        <label class="pt-4 w-full text-md tracking-widest border-t border-gray font-medium text-white uppercase font-euclid cursor-pointer leading-5">
                            {{$t('footer.mobile.language')}}
                        </label>    
                        <div class="mt-3 lg:mt-0 text-right relative cursor-pointer">
                            <label :id="'label_language'" class="text-white text-lg text-right cursor-pointer">></label>
                        </div>                              
                    </div>
                    <div :id="'description_language'" class="hidden w-full my-2 cursor-pointer accordion-description">
                        <LocaleSwitcherMobile />
                    </div>              
                </div>
            </div>
            <div @click="openAccordion('la')">
                <div class="flex flex-wrap cursor-pointer">
                    <div class="w-full flex cursor-pointer">
                        <label class="pt-4 w-full text-md tracking-widest border-t border-gray font-medium text-white uppercase font-euclid cursor-pointer leading-5">
                            {{$t('footer.mobile.la')}}
                        </label>    
                        <div class="mt-3 lg:mt-0 text-right relative cursor-pointer">
                            <label :id="'label_la'" class="text-white text-lg text-right cursor-pointer">></label>
                        </div>                              
                    </div>
                    <div :id="'description_la'" class="hidden w-full my-2 cursor-pointer accordion-description">
                        <ul class="text-md text-gray cursor-pointer leading-4">
                            <li>
                                <a :id="'MenuCollection'" @click="goTo('collection')">
                                    {{ $t('main.mainMenu.collection') }}
                                </a>
                            </li>
                            <li>
                                <a id="MenuServices" @click="goTo('services')">
                                    {{ $t('main.mainMenu.services') }}
                                </a>
                            </li>
                            <li>
                                <a id="MenuDistricts" @click="goTo('districts')">
                                    {{ $t('main.mainMenu.districts') }}
                                </a>
                            </li>
                            <li>
                                <a id="MenuThisIsLa" @click="goTo('thisisla')">
                                    {{ $t('main.mainMenu.thisisLA') }}
                                </a>
                            </li>
                        </ul>
                    </div>              
                </div>
            </div>
            <div @click="openAccordion('newsletter')">
                <div class="flex flex-wrap cursor-pointer">
                    <div class="w-full flex cursor-pointer">
                        <label class="pt-4 w-full text-md tracking-widest border-t border-gray font-medium text-white uppercase font-euclid cursor-pointer leading-5">
                            {{$t('footer.mobile.newsletter')}}
                        </label>    
                        <div class="mt-3 lg:mt-0 text-right relative cursor-pointer">
                            <label :id="'label_newsletter'" class="text-white text-lg text-right cursor-pointer">></label>
                        </div>                              
                    </div>
                    <div :id="'description_newsletter'" class="hidden w-full my-2 cursor-pointer accordion-description">
                        <p class="mt-2 text-sm">
                            {{$t('footer.extended.newsletter.text')}}
                            <br>
                            {{$t('footer.extended.newsletter.text2')}}<a @click="openPrivacy" class="cursor-pointer hover:text-bronze">{{$t('footer.extended.newsletter.privacy_policy')}}</a>
                            <form id="newsletterForm" @submit.prevent="processNewsletterForm" class="mt-4 flex w-full bg-black newsletter-form">
                                <input id="newsletterForm-email" class="px-4 w-full py-1 mr-0 text-white text-sm"
                                    type="email" :placeholder="$t('footer.extended.newsletter.placeholder')" />
                                <button
                                    form="newsletterForm"
                                    type="submit"
                                    class="px-2 w-1/3 bg-white text-black text-sm font-bold uppercase tracking-button whitespace-nowrap">{{$t('buttons.footerSubmit')}}</button>
                                <span class="hint">{{newsletterHint}}</span>
                            </form>
                    </div>              
                </div>
            </div>
            <div class="border-t border-gray w-full text-center text-gray pt-6">
                <div class="footer-logo">
                    <img width="100%" height="auto" class="m-auto mb-2" style="margin-bottom: -15%;margin-top: -22%;" src="/new_logos/2022-LA-Agency-Icon_Gold.png" />
                </div>
                <!-- contact info -->
                <p class="tracking-wider text-base font-medium uppercase">
                    {{$t('footer.extended.contact.title')}}
                </p>
                <p class=" mt-1 leading-relaxed text-sm">
                    {{$t('footer.extended.contact.adressLine1')}}
                    {{$t('footer.extended.contact.adressLine2')}}<br>
                    {{$t('footer.extended.contact.phone1')}}<br>
                    {{$t('footer.extended.contact.phone2')}}<br>
                    <small>{{$t('footer.extended.contact.aviso')}}</small>
                </p>
            </div>
            <div
                class="uppercase mt-4 tracking-wider m-0 border-white w-full bg-black text-gray text-sm">
                <div class="text-center">
                    <SocialsLink color="gray" />
                </div>
                <div class="text-center mt-2 leading-3">
                    <a target="_blank" href="https://www.livroreclamacoes.pt/inicio"
                        class="mr-2">{{$t('footer.extended.minimal.book')}} </a>
                    <br>
                    <router-link :to="{ name: 'privacy', params: { lang: this.$i18n.locale }}">
                        {{ $t('footer.extended.minimal.privacy') }}
                    </router-link>
                </div>
                <div class="text-center uppercase mt-2">
                    <p>Copyright© {{year}} EXCLUSIVEFAMOUS, Lda. All rights reserved.
                    </p>
                </div>
                
                
            </div>
        </div>
        
    </div>
</template>

<style lang="scss" scoped>
    .swiper-slide {
       width: 70%;
       .swiper-slide:last-child {
          width: 100%;
       }
       /* tablet */
       @media (min-width: 768px) {
           width: 30%;
           .swiper-slide:last-child {
              width: 100%;
          }
               
       }
  }
  #newsletterForm.newsletter-form{
    border: 2px solid white;
    position: relative;
    input{
      background: transparent;
    }
    button {
      border: 2px solid white;
    }
    .hint{
      position: absolute;
      left: 0;
      top: calc(100% + 10px);
      font-size: calc(.42vh + .42vw);
      line-height: calc(.64vh + .64vw);
      color: #ff4e29;
      font-weight: 700;
      visibility: hidden;
    }
    &.success {
      border-color: #00ba8a;
      button {
        border: 2px solid black;
      }
      .hint {
        color: #00ba8a;
        visibility: visible;
      }
    }
  }

</style>

<script>
import PropertyCardSmall from "@/components/listings/cards/PropertyCardSmall";
import PropertyCardBig from "@/components/listings/cards/PropertyCardBig";
import SocialsLink from "@/components/socials/SocialsLink.vue";
import LocaleSwitcherMobile from "@/components/core/LocaleSwitcherMobile.vue";

import {
        Swiper,
        SwiperSlide
    } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

// Execution code
import mixin from '@/models/general/GeneralModel'

export default {
  name: "FooterExtended",
  components: {
    PropertyCardSmall,
    PropertyCardBig,
    Swiper,
    SwiperSlide,
    SocialsLink,
    LocaleSwitcherMobile
  },
  mixins: [mixin],
  props:{
      properties: Boolean,
      items: null
  },
  data: function () {
        return {
            year: new Date().getFullYear(),
            newsletterHint: 'Subscribed with success',
            swiperOption: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            }
        }
    }, 
    methods:{
        goToCity(city){
            let isEnglish = this.$i18n.locale == 'en'
            let mainCity = city
            if(city == 'Lisbon')
                mainCity = 'Lisboa';

            let dst = 'dst=' + this.$districtCoder(mainCity.toUpperCase())
            if(mainCity == 'Cascais')
                dst = dst + '&mun=152'

            if(mainCity == 'Algarve'){
                mainCity = 'Faro'
                dst = 'dst=' + this.$districtCoder(mainCity.toUpperCase())
            }

            if(isEnglish){
                window.location.href = this.$ego_redirecter() + '/en-gb/properties?loc=' + mainCity + '&' + dst
            }else{
                window.location.href = this.$ego_redirecter() + '/imoveis?loc=' + mainCity + '&' + dst
            }
            
        },
        segment(s){
            let myLinkData = "segment:" + s
            this.$emit('segment-changed', s)
            this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
        },
        getCityAddress(i){
            if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
                return ''
            
            return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
        },
        resetNewsletterStatus(){
            const newsletter = document.getElementById('newsletterForm');
            newsletter.classList.remove('success');
        },
        generateLink(id){
            this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
        },
        async processNewsletterForm(){
            const newsletter = document.getElementById('newsletterForm');
            const email = document.getElementById('newsletterForm-email').value;
            if(email && email.length){
              const res = await this.newsletterSaving({target: 'newsletter', address: email});
              if(res){
                newsletter.classList.add('success');
              }
            }
        },
        openAccordion(tag){
            const label = document.getElementById('label_'+tag);
            const open = label?.textContent == 'v';
            this.closeAllAccordions();
            if(label && !open){
                label.textContent = 'v'
                const desc = document.getElementById('description_'+tag);
                desc.classList?.remove('hidden');

                /*if(scrollTo){
                    const page = document.getElementById('thisisla_fullpagemodal').firstElementChild;
                    page.scrollTo(0, desc.offsetTop + 100 + desc.offsetHeight);
                }*/
            }
        },
        closeAllAccordions(){
            document.getElementById('description_search').classList?.add('hidden')
            document.getElementById('label_search').textContent = '>';
            document.getElementById('description_language').classList?.add('hidden')
            document.getElementById('label_language').textContent = '>';
            document.getElementById('description_la').classList?.add('hidden')
            document.getElementById('label_la').textContent = '>';
            document.getElementById('description_newsletter').classList?.add('hidden')
            document.getElementById('label_newsletter').textContent = '>';
        },
        listCity(city){
            let myLinkData = "city:" + city
            this.$emit('city-changed', city)
            this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
        },
        listLifestyle(lifestyle){
            let myLinkData = "lifestyle:" + lifestyle
            this.$emit('lifestyle-changed', lifestyle)
            this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
        },
        openPrivacy(){
            let routeData = this.$router.resolve({name: 'privacy', params: { lang: this.$i18n.locale }});
            window.open(routeData.href, '_blank');
        },
        goTo(target){
            switch(target){
                case 'home':
                    this.$router.push({ name: target, params: { lang: this.$i18n.locale}})
                    break;
                case 'collection':
                    this.$router.push({ name: 'home', hash: '#collection_content', params: { lang: this.$i18n.locale}})
                    break;
                case 'districts':
                        this.$router.push({ name: 'districts', params: { lang: this.$i18n.locale, slug: 2 }})
                    break;
                case 'services':
                    this.$router.push({ name: 'home', hash: '#services_content', params: { lang: this.$i18n.locale}})
                    break;
                case 'thisisla':
                    this.$router.push({ name: 'ThisIsLA', params: { lang: this.$i18n.locale}})
                    break;
                case 'sellwithus':
                    this.$router.push({ name: 'sellwithus', params: { lang: this.$i18n.locale}})
                    break;
            }
        },
    },
    computed:{
        computeProperties(){
            if(this.items == null)
                return null
            if(this.items.length == 0)
                return null
            
            return this.items.slice(0, 6)
        },
        mobileComputeProperties(){
            if(this.items == null)
                return null
            if(this.items.length == 0)
                return null
            
            return this.items
        }
    }
}
</script>