<template>
       <div class="absolute top-2 right-2 md:top-3 md:right-3">
              <!-- small tag -->
           <div v-if="size === 'small'" class="rounded-sm md:rounded-md p-1 md:p-2 bg-black flex items-center justify-center">
               <p class="tracking-tagSold text-xs leading-none font-medium uppercase text-white">{{$processLanguage(tag)}}</p>
           </div>
             <!-- big tag -->
             <div v-else class="rounded-md md:rounded-lg p-0 h-5 md:h-auto px-2 md:py-2 md:px-3 bg-black flex items-center justify-center">
               <p class="tracking-tagSold leading-none text-sm font-medium uppercase text-white">{{$processLanguage(tag)}}</p>
           </div>
       </div>
</template>
<script>
    export default {
        name: "PropertyCardTags",
        props: ["tag", "size"],
    }
</script>