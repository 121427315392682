<template>
   <div class="mt-2 text-sm">
      <input :name="name" class="styled-checkbox" :id="id" type="checkbox" value="accepted"
         unchecked-value="not_accepted">
      <label :for="id">{{text}} <span @click="generateLink()" class="underline font-bold"> {{privacy}} </span></label>
   </div>
</template>

<script>

   export default {
      name: "iCheckbox",
      props: ["name", "text", "privacy", "id"],
      components:{
      },
      data() {
         return {
               value: "",
               status: "not_accepted",
               privacyModal: false,
            }
      },
      methods:{
         closePrivacyModal(){
            this.privacyModal = false
         },
         generateLink(){
            let routeData = this.$router.resolve({name: 'privacy', params: { lang: this.$i18n.locale }});
            window.open(routeData.href, '_blank');
         }
      }
   }
</script>
