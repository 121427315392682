<template>
    <transition name="modal-fade">
        <div class="modal-backdrop fixed inset-0 overflow-y-auto p-4 md:overflow-hidden md:p-0 bg-black bg-opacity-50	lg:flex justify-center items-center z-125">
            <div class="bg-white py-2 md:py-4 lg:py-8 rounded-lg w-full h-auto lg:w-8/12 mt-10 lg:mt-0 lg:h-5/6"
            :class="noclose && 'formModal-noclose'">
                <header v-if="!noclose" class="flex relative items-center justify-end px-2 md:px-4 lg:px-8">
                    <button @click="close">
                        <inline-svg class="transform origin-center rotate-45 w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" fill="black"
                            src="/svg/plus.svg" />
                    </button>
                </header>
                <!-- begin:: modal boody -->
                <section class="modal-body mt-2 lg:mt-6 h-full" :class="noclose && 'formModal-noclose'">
                    <slot name="body">
                        This is the default body!
                    </slot>
                </section>
                <!-- end:: modal boody -->
            </div>
        </div>
    </transition>
</template>
<style scoped>
.formModal-noclose{
  padding-top: 0;
  margin-top:0;
  border-radius: 23px;
  overflow-y:none;
}
@media (max-width:1150px){
  .formModal-noclose{
    overflow-y:auto;
    display:flex;
  }
}
</style>
<script>
  export default {
    name: 'FormModal',
    props:{
      noclose:{
        type: Boolean,
        default: false
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
       show() {
        this.$emit('show');
      },
    },
  };
</script>