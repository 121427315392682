import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import { store } from '@/store'
import mediaFile from '@/plugins/media'
import currency from '@/plugins/currency'
import cutText from '@/plugins/cutText'
import processLanguage from '@/plugins/processLanguage'
import isFromAlgarve from '@/plugins/isFromAlgarve'
import isDistrict from '@/plugins/isDistrict'
import ego_redirecter from '@/plugins/ego_redirecter'
import districtCoder from '@/plugins/districtCoder'
import processPrintLanguage from '@/plugins/processPrintLanguage'
import '@/plugins/axios'
import '@/plugins/form'
import '@/plugins/googleMaps'
import '@/plugins/common'
// MEUS
import VueCompositionAPI from '@vue/composition-api'
import VueRadioToggleButtons from 'vue-radio-toggle-buttons';
import VueCurrencyInput from 'vue-currency-input'
import VueSocialSharing from 'vue-social-sharing'
import shareIt from 'vue-share-it';
import VideoBackground from 'vue-responsive-video-background-player'
import VueCookies from 'vue-cookies'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueLazyload from 'vue-lazyload'

import VueAnimate from 'vue-animate-scroll'
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.config.productionTip = false

Vue.prototype.$ego_redirecter = ego_redirecter
Vue.prototype.$districtCoder = districtCoder
Vue.prototype.$mediaFile = mediaFile
Vue.prototype.$processLanguage = processLanguage
Vue.prototype.$isFromAlgarve = isFromAlgarve
Vue.prototype.$isDistrict = isDistrict
Vue.prototype.$processPrintLanguage = processPrintLanguage
Vue.prototype.$currency = currency
Vue.prototype.$cutText = cutText


// Import Styles
import './assets/scss/app.template.css'
import './assets/scss/style.vue.css'
import 'vue-radio-toggle-buttons/dist/vue-radio-toggle-buttons.css';
import 'vue2-animate/dist/vue2-animate.min.css'

// Vue 3rd party plugins
import {InlineSvgPlugin} from 'vue-inline-svg';
import vueVimeoPlayer from 'vue-vimeo-player';

Vue.use(VueCookies);
// Setting up cookie
Vue.$cookies.config('7d')


Vue.use(VueTippy);
Vue.use(VueYouTubeEmbed)
Vue.component("tippy", TippyComponent);
Vue.use(vueVimeoPlayer);
Vue.use(VueAnimate);
Vue.use(VueCompositionAPI);
Vue.use(VueRadioToggleButtons);
Vue.use(VueCurrencyInput);
Vue.use(VueSocialSharing);
Vue.use(shareIt);
Vue.use(VueLazyload)
Vue.component('video-background', VideoBackground);
import i18n from './i18n'
Vue.use(InlineSvgPlugin);

Vue.directive('click-outside',{
  bind: function (el, binding, vnode) {
      el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes');
      }
      el.eventClearDrag = function () {
          el.removeAttribute('data-dragging');
      }
      el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging');
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
              // call method provided in attribute value
              vnode.context[binding.expression](event);
          }
      };
      document.addEventListener('touchstart', el.eventClearDrag);
      document.addEventListener('touchmove', el.eventSetDrag);
      document.addEventListener('click', el.eventOnClick);
      document.addEventListener('touchend', el.eventOnClick);
  }, unbind: function (el) {
      document.removeEventListener('touchstart', el.eventClearDrag);
      document.removeEventListener('touchmove', el.eventSetDrag);
      document.removeEventListener('click', el.eventOnClick);
      document.removeEventListener('touchend', el.eventOnClick);
      el.removeAttribute('data-dragging');
  },
});


new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
