function isFromAlgarve(value) {
    const algarveCounty = [
        'Albufeira',
        'Alcoutim',
        'Aljezur',
        'Castro Marim',
        'Faro',
        'Lagoa',
        'Lagos',
        'Loulé',
        'Monchique',
        'Olhão',
        'Portimão',
        'São Brás de Alportel',
        'Silves',
        'Tavira',
        'Vila do Bispo',
        'Vila Real de Santo António'
    ]

    return algarveCounty.includes(value)
    
  }
  
  export default isFromAlgarve