import axios from 'axios'

import { getFormDataMultidimensional } from '@/utils/utils'

export default {
  saveNewsletterEmail(params){
      return axios.post(`front/saveMessage/`+params.block, getFormDataMultidimensional(params),
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },
    getWhoWeAreDescription(params) {
      return axios.get('/front/getWhoWeAreDescription', {
        params
      })
    },
  
    getAllAgents(params) {
      return axios.get('/agents', {
        params
      })
    },
    getServiceDescription(params) {
      return axios.get('/services/description', {
        params
      })
    },
    saveHomeMessage(params){
  
        return axios.post(`front/saveMessage/`+params.block, getFormDataMultidimensional(params.data),
           {
             headers: {
               'Content-Type': 'multipart/form-data'
             }
           }
         )
      },
      bookVisitMessage(params){    
          return axios.post(`front/bookVisitMessage`, getFormDataMultidimensional(params.data),
             {
               headers: {
                 'Content-Type': 'multipart/form-data'
               }
             }
           )
        },
      vineyardContactMessage(params){
        return axios.post(`front/saveMessage/vineyardContactMessage`, getFormDataMultidimensional(params.data),
             {
               headers: {
                 'Content-Type': 'multipart/form-data'
               }
             }
           )
      },
      workWitheMeContactMessage(params){
        return axios.post(`front/saveMessage/workWitheMeContactMessage`, getFormDataMultidimensional(params.data),
             {
               headers: {
                 'Content-Type': 'multipart/form-data'
               }
             }
           )
      },


      
        sellWithUsRequest(params){      
            return axios.post(`front/saveMessage/`+params.block, getFormDataMultidimensional(params.data),
               {
                 headers: {
                   'Content-Type': 'multipart/form-data'
                 }
               }
             )
          },
          getAllServices(){      
            return axios.get(`front/getServices`,
               {
                 headers: {
                   'Content-Type': 'multipart/form-data'
                 }
               }
             )
          },
          getServiceDescriptionFront(){      
            return axios.get(`front/getServiceDescription`,
               {
                 headers: {
                   'Content-Type': 'multipart/form-data'
                 }
               }
             )
          },      
      getAllMapMarkers(){    
          return axios.get(`front/allLocationPoints`,
             {
               headers: {
                 'Content-Type': 'multipart/form-data'
               }
             }
           )
        },
        getAllMapMarkersById(id){    
          return axios.get(`front/allLocationPoints/`+id,
             {
               headers: {
                 'Content-Type': 'multipart/form-data'
               }
             }
           )
        },



        
      
      
}



