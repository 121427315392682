import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Properties action called.`)
  },

  methods: {
    ...mapActions(['getHomepageCollectionPrivee', 'homepageGetProperties', 'getProperties', 'getPropertyBlock', 'getMediaBlock', 'getAttachmentBlock', 'getPropertiesFromCondominium']),

    
  }
}

export default mixin