<template>
    <div :class="'text-'+color" class="flex space-x-3 justify-center items-center">
        <a href="https://www.youtube.com/channel/UCH2b9CoNRmuJQyWZY7FcSqg" target="_blank">
            <inline-svg class="lg:h-6 lg:w-6 w-4 h-4 fill-current" src="/svg/socials/youtube.svg" />
        </a>
        <a href="https://www.linkedin.com/company/la-agencyre/" target="_blank">
            <inline-svg class="lg:h-6 lg:w-6 w-4 h-4 fill-current" src="/svg/socials/linkedin.svg" />
        </a>
        <a href="https://www.instagram.com/laagencyre/" target="_blank">
            <inline-svg class="lg:h-6 lg:w-6 w-4 h-4 fill-current" src="/svg/socials/instagram.svg" />
        </a>
        <a href="https://www.facebook.com/La-Agency-RE-110488430616440/" target="_blank">
            <inline-svg class="lg:h-6 lg:w-6 w-4 h-4 fill-current" src="/svg/socials/facebook.svg" />
        </a>
    </div>
</template>
<script>

export default {
    name: "SocialsLink",
    props: [ "color"]
       
}
</script>