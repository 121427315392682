import axios from 'axios'
//import { getFormDataMultidimensional } from '@/utils/utils'

export default {
    getHomepageData(params) {
        return axios.get('/front/homepage', {
        params
        })
  }

  
}
