import * as types from '@/store/mutation-types'
import api from '@/services/api/agents/agents'
import { handleError } from '@/utils/utils.js'
//import router from '@/router'

const getters = {
  list: (state) => state.list,
  agent: (state) => state.agent,
  totalAgents: (state) => state.totalAgents
}

const actions = {
  getAgents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getAgents(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.AGENTS, response.data.agents)
            commit(types.TOTAL_AGENTS, response.data.total)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getAgentSingleData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getAgentSingleData(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.AGENT, response.data.agent)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }
}

const mutations = {
  [types.AGENTS](state, list) {
    state.list = list
  },
  [types.AGENT](state, list) {
    state.agent = list
  },
  [types.TOTAL_AGENTS](state, value) {
    state.totalAgents = value
  }
}

const state = {
  list: [],
  totalAgents: 0,
  agent: []
}

export default {
  state,
  getters,
  actions,
  mutations
}