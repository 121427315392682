export class Service {
    constructor({
        id = String(),
        title= Array(),
        description= Array()
    }) {
        this.id = id || null
        this.i18n = {
            title: { en: title.en || '', pt: title.pt || '' },
            description: { en: description.en || '', pt: description.pt || '' },
          }
    }
}

export class ServiceDescription {
    constructor({
        id = String(),
        description= Array()
    }) {
        this.id = id || null
        this.i18n = {
            description: { en: description.en || '', pt: description.pt || '' },
          }
    }
}

export class ServiceDescriptionForm{
    constructor({
      id = null,
      description = '',
    }) {
    this.id = id || null
    this.description = {
      en: description.en || '',
      pt: description.pt || ''
    } 
    }
}


export default { Service, ServiceDescription, ServiceDescriptionForm }