function districtCoder(district) {
    if(district == 'FARO')
        return 8

    if(district == 'PORTO')
        return 13

    if(district == 'LISBOA')
        return 11

    return 11
  }
  //https://20785-1.ep.egorealestate.com
  
  export default districtCoder