import axios from 'axios'
//import { getFormData } from '@/utils/utils'

export default {
  getAgents(params) {
    return axios.get('/agents', {
      params
    })
  },
  getAgentSingleData(params) {
    return axios.get(`/agents/getTheAgent/${params}`, {
      params
    })
  }
}
