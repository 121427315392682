<template>
    <div class="mt-8 md:mt-16 lg:mt-24 relative">
        <!-- begin::title -->
        <div class="flex items-center flex-col p-5 lg:p-0 ">
            <p class="md:text-sectionTitle text-xl text-center font-understock">{{ $t('homepage.collection.title') }}
            </p>
            <p class="text-baselg text-center font-blackMango tracking-wider lg:w-2/3">
                {{ $t('homepage.collection.subtitle') }} </p>
        </div>

        <!-- end::title -->
        <!-- begin:: mobile version -->
        <transition mode="out-in" name="slide-fade">
            <CollectionMobile @toNextProject="toNextProject" @toPreviousProject="toPreviousProject" v-if="project"
                class="flex lg:hidden h-screen-75 md:h-screen-50 w-screen" :project="project" :key="project.id" />
        </transition>
        <!-- end:: mobile version -->
        <!-- begin:: desktop version -->
        

            <!-- end::project info -->
            <!-- end:: destop version -->

            <div class="containerSlider hidden lg:block">
              <div class="wrapperSlider">
                <div class="slide" :key="i" v-for="(item, i) in items">
                    <div class="grid-cols-3 hidden mt-10 lg:grid lg:h-screen-85 overflow-hidden border-t border-b border-black">
                      <!-- begin::image slide -->
                      <div class="col-span-2 overflow-hidden">
                          <CollectionProjectImage :project="item" :key="item.id"/>
                      </div>
                      <!-- end::image slide -->
                      <!-- begin::project info -->
                      <div class="col-span-1 hidden lg:grid overflow-hidden" >
                          <CollectionProjectText 
                            :projectListLength="items.length" 
                            :currentProject="i"
                            :project="item" />
                      </div>
                    </div>
                </div>
                <div class="slide" :key="-1">
                    <div class="grid grid-cols-3 hidden mt-10 lg:grid lg:h-screen-85 overflow-hidden border-t border-b border-black">
                      <!-- begin::image slide -->
                      <div class="col-span-2 overflow-hidden">
                          <CollectionProjectImage :project="items[0]" :key="items[0].id"/>
                      </div>
                      <!-- end::image slide -->
                      <!-- begin::project info -->
                      <div class="col-span-1 hidden lg:grid overflow-hidden" >
                          <CollectionProjectText 
                            :projectListLength="items.length" 
                            :currentProject="0"
                            :project="items[0]" />
                      </div>
                    </div>
                </div>
              </div>
              <div class="steps"></div>
              <button class="next" @click="onNext">
                  <inline-svg height="15" fill="white" src="/svg/arrow/arrow.svg" />
              </button>
            </div>
    </div>
</template>
<style scoped>
  .containerSlider{
    margin-top:30px;
    position: relative;
    --step: 0;
    --slide: 0;
    --length: 0;
  }
  .wrapperSlider{
    position: relative;
    display: flex;
    width: 100%;
    overflow-x: hidden;
  }
  .steps {
    position: relative;
    background: var(--color-lightgray);
    height: 4px;
    width: 100%;
    margin-top: 20px;
    border-radius: 2px;
  }
  .steps::after{
    content:'';
    display: block;
    background: var(--color-bronze);
    height: 100%;
    border-radius: 2px;
    width: calc( 100% / var(--length) );
    transform: translate(calc( 100% * var(--step) ), 0);
    transition: transform 1s ease-out;
  }
  .next {
    position: absolute;
    z-index: 40;
    top: 50%;
    transform: translateY(-50%);
    right: 1%;
    width: 50px;
    height: 50px;
    background-color: var(--color-bronze);
    cursor: pointer;
    border: unset;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slide {
    flex:none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transform: translate(calc( -100% * var(--slide) ), 0);
    transition: transform 1s ease-out;
    width: 100%;
  }
  .slide > * {
    transform-origin: bottom;
    position: relative;
    margin: 0;
  }
  .aniprevious + .slide > * {
    animation: scaledown .95s linear forwards;
  }
  .aniprevious {
    transition: transform 2s linear;
  }
  .anireset .slide{
    transition: unset;
  }
  img {
    height: 100%;
  }
  @keyframes scaledown {
    from{
      transform: scale(1.5);
    }
    to {
      transform: scale(1);
    }
  }
</style>
<script>
    import CollectionProjectImage from "@/components/collection/CollectionProjectImage";
    import CollectionProjectText from "@/components/collection/CollectionProjectText";
    import CollectionMobile from "@/components/collection/CollectionMobile";

    export default {
        name: "Collection",
        props: ['items'],
        components: {
            CollectionProjectImage,
            CollectionProjectText,
            CollectionMobile,
        },
        data: function () {
            return {
              project: this.items[0],
              currentProject: 0,
              nextSlide: () => void 0
            }
        },
        mounted(){
          setTimeout(() => {
            const containerSlider = document.getElementsByClassName('containerSlider')[0];
            const next = document.getElementsByClassName('next')[0] || {};
            const children = document.getElementsByClassName('slide');
            let i = 0;
            const size = children.length - 1;
            containerSlider.style.setProperty("--length", size);
            children[size].addEventListener('transitionend', () => {
              if( i != size) return;
              requestAnimationFrame(() => {
                containerSlider.classList?.add('anireset');
                children[i-1]?.classList?.remove('aniprevious');
                containerSlider.style.setProperty("--slide", i = 0);
                requestAnimationFrame(() => {
                  containerSlider.classList.remove('anireset');
                })
              })
            })
            function onNext(){
              if(i >= size)return;
              next.disabled = true;
              requestAnimationFrame(() => {
                setTimeout(() => (next.disabled = false), 700);
                children[i-1]?.classList?.remove('aniprevious');
                children[i++]?.classList?.add('aniprevious');
                containerSlider.style.setProperty("--step", i == size ? 0 : i);
                containerSlider.style.setProperty("--slide", i);
              })
            }
            this.nextSlide = onNext;
          })
        },
        methods: {
            onNext(){
              return this.nextSlide()
            },
            toNextProject(){
              if(this.currentProject != this.items.length - 1){
                this.currentProject++;
                this.project = this.items[this.currentProject];
              }
              else{
                this.currentProject=0;
                this.project = this.items[this.currentProject];
              }
            },
            toPreviousProject(){
              if(this.currentProject!=1){
                this.currentProject--;
                this.project = this.items[this.currentProject];
              }
              else{
                this.currentProject=this.items.length - 1;
                this.project = this.items[this.currentProject];
              }
            }
        },
    };
</script>