<template>
    <div class="featuresListPrincipal">
      <div                      
          class="featuresListItem text-baselg font-euclid mb-2"
          v-for="(item) in toBeShown" 
          :key="item.id">
                
        <input  
                type="checkbox"
                :id="item.id"
                :class="customClass + ' text-baselg font-euclid roundCheck'"
                :label="item.name.en"
                v-model="checkbox"
                :color="color"
                :value="item.id"
                @change="updateValue"
        />        
        <label :for="item.id" class="ml-4">{{ $processLanguage(item.name)}}</label>
        </div>

        <a @click="updateShowDiv" v-show="showMoreBTN" class="text-bronze mt-4 font-euclid text-baselg cursor-pointer">{{ $t('advancedFilters.showMore') }}</a>

        <div v-show="showMoreDiv"                      
          class="featuresListItem text-baselg font-euclid mb-2"
          v-for="(item, index) in showMore" 
          :key="index+'A'">
                
        <input  
                type="checkbox"
                :id="item.id"
                :class="customClass + ' text-baselg font-euclid roundCheck'"
                :label="item.name.en"
                v-model="checkbox"
                :color="color"
                :value="item.id"
                @change="updateValue"
        />        
        <label :for="item.id" class="ml-4">{{ $processLanguage(item.name)}}</label>
        </div>

        <a @click="updateShowDiv" v-show="showlessBTN" class="text-bronze mt-4 font-euclid text-baselg cursor-pointer">{{ $t('advancedFilters.showLess') }}</a>
    </div>
</template>
<style scoped>
.featuresListPrincipal{
  display: flex; 
  flex-wrap: wrap; 
  width: 100%;
}
.featuresListItem{
  flex: 0 0 50%;
}
.roundCheck{
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #B95318;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.roundCheck:checked {
  background-color: #B95318;
}
</style>
<script>
  export default {
    name: 'InputCheckboxMultiple',

    props: {
      items: {
        type: Array,
        default: null
      },
      label: {
        type: String,
        default: 'Checkbox'
      },
      color: {
        type: String,
        default: 'secondary'
      },
      status: {
        type: Number,
        default: 1
      },
      customClass: String,
      fill: Array,
      checkedItems: null,
    },

    data() {
      return {
        dataLoading: false,
        checkbox: [],
        numberToShow: 8,
        showMoreDiv: false,
        showMoreBTN: true,
        showlessBTN: false
      }
    },
    created(){
      this.checkbox = this.checkedItems        
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      },
      toBeShown(){
        let toShow = []
        let counter = 0
        this.items.forEach(element => {
          if(counter < this.numberToShow)
            toShow.push(element)

          counter++
        });



        return toShow
      },
      showMore(){
        let toShow = []
        let counter = 0
        this.items.forEach(element => {
          if(counter >= this.numberToShow)
            toShow.push(element)

          counter++
        });

        return toShow
      }
    },

    watch: {
      fill: {
        handler: function(val) {
          if (typeof val !== 'undefined') {
            this.checkbox = val
          }
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      updateValue(e) {
        this.$emit('input', e)
      },
      updateShowDiv(){
        this.showMoreDiv = !this.showMoreDiv
        this.showMoreBTN = !this.showMoreBTN
        this.showlessBTN = !this.showlessBTN
      }
    }
  }
</script>
