<template>
    <div class="fullpagemodal-wrapper2 no-scrollbar">
        <div class="fullpagemodal-wrapper" :class="normalClass">
            <div class="fullpagemodal-body bg-white">
            <div class="flex justify-center mt-0 scroll-cta" @click="openContent">
                <div class="cta-button bg-black lg:w-1/12 rounded-lg h-1 md:h-2 mr-0 ml-0"></div>
            </div>
            <div v-if="ctaText" class="flex justify-center w-10/12 lg:w-full mx-auto lg:mx-0 text-sectionTitle  base:text-hero font-euclid text-black uppercase">
                    <p class="lowercase text-base"> {{ ctaText }}</p>
            </div>
            
            <slot></slot>
        </div>
        </div>
    </div>
</template>
<style scoped>
.fullpagemodal-wrapper2{
  margin-top: 160px;
  position: fixed;
  top: 0;
  height: 100%;
  overflow: hidden;
  z-index: 20;
  width: 100vw;
}
.fullpagemodal-wrapper{
  --header-padding: 225px;
  padding-top: calc( (var(--vh, 1vh) * 100) - var(--header-padding) );
  position: relative;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top:0;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.fullpagemodal-body{
  margin-top: 0;
  margin-bottom: 200px;
  position: relative;
  height: auto;
  top: unset;
  padding-top: 0;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.scroll-cta{
  height: 5rem;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.scroll-cta > .cta-button {
  margin:0;
  margin-bottom: 1.5rem;
}

@media (max-width: 720px) {
  .fullpagemodal-wrapper2{
      margin-top: 0px;
  }
  .fullpagemodal-wrapper{
      margin-top: 80px;
    --header-padding: 120px;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .fullpagemodal-body{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    margin-bottom: 20px;
  }
  .scroll-cta{
    height: 3rem;
  }
  .scroll-cta .cta-button {
    width: 18.333333%;
  }
  .text-sectionTitle{
    text-align:center;
  }
}
</style>
<script>
export default {
    name: "FullPageModal",
    props: {
      ctaText: String,
    },
    setup(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      let productDetailsWrapper = undefined;
      setTimeout(function(){
        document.querySelector('.fullpagemodal-wrapper').scrollTo({
          top: (window.innerHeight - 182),
          left: 0,
          behavior: 'smooth'
        });
      },2000);
      function getWrapperOffset(){
        return +getComputedStyle(productDetailsWrapper).getPropertyValue('--header-padding').replace('px','');
      }
      function openContent(){
        const target = productDetailsWrapper || (productDetailsWrapper = document.querySelector('.fullpagemodal-wrapper'));
        const { scrollTop, clientHeight } = target;
        const position = scrollTop ? 0 : (clientHeight - getWrapperOffset());
        target.scrollTo(0, position);
        
      }
      return {
        openContent
      } 
    },
    data(){
        return{
            normalClass: 'text-baselg max-w-8xl md:w-11/12 w-full inset-x-0 mx-auto z-20 text-black pt-3 md:pt-6 lg:pt-20 overflow-scroll hide-scrollbar h-full',
        }
    },
}
</script>