import { mapActions } from 'vuex'
//import { buildPayloadPagination } from '@/utils/utils'

const mixin = {

  created: function() {
    console.debug(`Agents action called.`)
  },

  methods: {
    ...mapActions(['getAgents', 'getAgentSingleData']),

    async getMyAgent(data){
      try{
          // SAVE MARKERS
          await this.getAgentSingleData(data)
        }catch(error){
          this.dataTableLoading = false
        }
    },

  }
}

export default mixin