<template>
        <input 
                :id="'input-' + name"
                :name="name"
                :placeholder="placeholder" 
                :type="type" 
                class="w-full md:h-10 bg-transparent text-base placeholder-gray-500 border-b border-black transition-opacity border-opacity-25 focus:shadow-outline focus:border-opacity-100" 
                v-model="value"
                @change="onChange"
                v-on:keyup="onKeyPress">
</template>
<style scoped>
    input{
        -webkit-appearance: none;
        -webkit-border-radius:0px
    }
</style>
<script>
    export default {
        name: "iText",
        props: ["name", "placeholder", "type"],
        data() {
            return {
                value: ""
            }
        },
        methods: {
            onChange(e){
                this.$emit('on-change', e)
            },
            onKeyPress(e){                
                this.$emit('on-keyPress', e)
            }
        },
    };
</script>