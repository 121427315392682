  import Vue from 'vue'
  import VueRouter from 'vue-router'
  import i18n from '../i18n'

  Vue.use(VueRouter)

  // Set main Route
  let mainRoute = "pages/Home";
  if (Vue.prototype.$offline) {
    mainRoute = "status/Offline";
  }


  import ThisIsLA from "@/views/pages/ThisIsLA"
  import Collection from "@/views/pages/Collection"


  const routes = [{
      path: "/:locale",
      component: () => import("@/layout/Main"),
      beforeEnter: (to, from, next) => {
        const locale = to.params.locale;
        const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
        if (!supported_locales.includes(locale)) return next('en');
        if (i18n.locale !== locale) {
          i18n.locale = locale;
        }
        return next()
      },
      children: [
        {
          path: "realestatelisting",
          name: "real estate listings",
          component: () => import("@/views/pages/RealEstate")
        },
        {
          path: "",
          name: "home",
          component: () => import("@/views/" + mainRoute)
        },
        {
          path: "collection",
          name: "collection", 
          component: Collection
        },
        {
          path: "services",
          name: "services",
          component: () => import("@/views/pages/ThisIsLA")
        },
        {
          path: "thisisLA",
          name: "ThisIsLA",
          component: ThisIsLA
        },
        {
          path: "zoom",
          name: "zoom",
          component: () => import("@/views/pages/ThisIsLA")
        },
        {
          path: "sellwithus",
          name: "sellwithus",
          component: () => import("@/views/pages/SellWithUs")
        },
        {
          path: "listing/:slug/",
          name: "listing",
          component: () => import("@/views/pages/ProductPageListing")
        },
        {
          path: "listing/realestatelisting/",
          name: "realestatelisting",
          component: () => import("@/views/pages/RealEstate")
        },
        {
          path: "development/:slug",
          name: "development",
          component: () => import("@/views/pages/ProductPageDevelopment")
        },
        {
          path: "privacy",
          name: "privacy",
          component: () => import("@/views/pages/Privacy")
        },
      ]
    },
    {
      path: "/:locale",
      component: () => import("@/layout/Districts"),
      beforeEnter: (to, from, next) => {
        const locale = to.params.locale;
        const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
        if (!supported_locales.includes(locale)) return next('en');
        if (i18n.locale !== locale) {
          i18n.locale = locale;
        }
        return next()
      },
      children: [{
        path: "district/:slug/",
        name: "districts",
        component: () => import("@/views/pages/DistrictPage")
      }]
    },
    {
      path: "/:locale",
      component: () => import("@/layout/Listings"),
      beforeEnter: (to, from, next) => {
        const locale = to.params.locale;
        const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
        if (!supported_locales.includes(locale)) return next('en');
        if (i18n.locale !== locale) {
          i18n.locale = locale;
        }
        return next()
      },
      children: [
        //{
        //  path: "listings",
        //  name: "listings",
        //  component: () => import("@/views/pages/ListingsPage")
        //},
        {
          path: "listings/:data",
          name: "listings",
          component: () => import("@/views/pages/ListingsPage")
        }
      ],
    },
    {
      path: "/:locale",
      component: () => import("@/layout/Blank"),
      children: [{
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/status/Error")
      }, ]
    },
    {
      path: "/:locale/print/",
      component: () => import("@/layout/Blank"),
      children: [
        {
          path: ':block',
          name: 'printBlock',
          component: () => import("@/components/printing/PrintView")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    }
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition;

      }
        if (to.hash) {
          return { selector: to.hash };
      }
      return { x: 0, y: 0 }
    },
  })
  export default router