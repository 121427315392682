<template>
    <div class="">
        <label class="labels ">{{label}}</label>
        <textarea
        :placeholder="placeholder" :name="name" 
        class="mt-3 md:mt-5 lg:mt-8 w-full text-base bg-transparent placeholder-gray-500 border-b border-black transition-opacity border-opacity-25 focus:shadow-outline focus:border-opacity-100" 
        :id="id" no-resize
        rows="5" v-model="value"
        ></textarea>
    </div>
</template>

<script>
    export default {
        name: "iTextarea",
         props: {
            name: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            placeholder: {
                type: String,
                default: ""
            },
            id:{
                type: String,
                default: "textarea"
            }
         },
        data() {
            return {
                value: ""
            }
        }
    }
</script>