<template>
  <div class="w-full">
    <div class="relative container">
      <!-- tag -->
      <div class="absolute w-full p-3 z-10">
          <PropertyCardTags v-if="tag" :tag="tag" size="big" />
      </div>
          <!-- price -->
        <div class="absolute left-2 bottom-2 md:left-3 md:bottom-3 z-10 flex justify-center items-center rounded-md md:rounded-lg p-0 h-5 md:h-auto px-2 md:py-1 md:px-4 glass-effect glass-effect-white glass-fallback">
          <p class="text-darkgray text-base font-semibold">{{price}}</p>
        </div>
      <!-- image -->
         <div :key="reloadKeyImage" class="overflow-hidden wrapperImage rounded-lg hover:border-bronze border-2 transition-all duration-300 border-transparent lg:rounded-2xl w-full">
             <div class="bgimage" v-lazy:background-image="img" ></div>

              <!-- THIS COMPONENT IMAGE IS FOR HELP. ITS THE ONLY WAY TO GET THE ERROR IF THE LOAD FAILS -->
              <img  class="bgimage" v-show="false"
                    :src="img" @error="errorImage">

          </div>
    </div>
    <p class="font-blackMango tracking-wide text-baselg text-center lg:text-left flex items-center listings-pin-ref" v-if="location && !isMobile">
      <span class="hidden md:flex items-center">
        <span class="text-bronze text-xl mr-2 location-bullet">&#8226;</span>
        <span class="font-euclid listings-location">{{ location }}</span>
        <span v-if="cityAddress" class="listings-location-delimiter">-</span>
        <span v-if="cityAddress" class="text-gray text-sm pt-1">{{cityAddress}} </span>
      </span>
      <span class="block md:hidden text-left">
        <p class="font-euclid listings-location">{{ location }}</p>
        <p v-if="cityAddress" class="text-gray text-sm">{{cityAddress}}</p>
      </span>
      <span class="font-euclid text-sm listing-ref" v-if="reference">ref. {{reference}}</span>
    </p>
    <!-- description/slogan -->
    <p v-if="slogan && !isListingView" class="flex space-x-1 md:space-x-2 lg:space-x-3 text-base whitespace-pre-line mt-2 truncate font-euclid tracking-wider text-baselg font-blackMango font-bold tracking-wider uppercase">
      {{slogan}}
    </p>
    <p v-if="slogan && isListingView" class="flex space-x-1 md:space-x-2 lg:space-x-3 text-sm whitespace-pre-line mt-2 truncate font-euclid tracking-wider text-sm font-blackMango font-bold tracking-wider uppercase">
      {{slogan}}
    </p>
    <div class="flex flex-col ">
      <!-- house details -->
      <div class="flex space-x-3 md:space-x-2 lg:space-x-3">
        <!-- room svg viewbox should be this 5 20 37 26 -->
        <PropertyDetailsBig :map="inMap" :number='rooms' img="/svg/houseDetails/rooms.svg" :color="color" :measure="measure" class="property-icons" svgClass="listing-icons"/>
        <PropertyDetailsBig :map="inMap" :number='baths' img="/svg/houseDetails/baths.svg" :color="color" :measure="measure" class="property-icons" svgClass="listing-icons"/>
        <PropertyDetailsBig :map="inMap" :number='garage' img="/svg/houseDetails/garage.svg" :color="color" :measure="measure" class="property-icons" svgClass="listing-icons"/>
        <PropertyDetailsBig :map="inMap" :number='area' img="/svg/houseDetails/area.svg" :color="color"  :measure="$t('propertyCard.area')" class="property-icons" svgClass="listing-icons"/>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.listings-pin-ref{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  .location-bullet{
    transform: translateY(-2px);
    line-height: 0;
  }
  .listings-location{
    font-weight: 500;
  }
  .listings-location-delimiter{
    padding: 0 .5rem;
    font-weight: bold;
    font-size: 2rem;
    line-height: 0;
  }
  .listing-ref{
    margin-left: 10px;
    color: var(--color-gray);
    font-weight: 600;
  }
}
.property-icons{
  height: 1.4rem;
}
.wrapperImage{
  height: calc( ( 83.33vh - 10rem ) / 3 * 9 / 16 );
  // Rui Moreira Size
  // min-height: 210px;
  min-height: 310px;
}

.bgimage{
  background-position: center;
  background-size: cover;
  display: block;
  height: 100%;
  width: 100%;
}
.container {
  cursor: pointer;
}
</style>
<script>
import PropertyCardTags from "@/components/listings/cards/PropertyCardTags";
import PropertyDetailsBig from "@/components/listings/cards/PropertyDetailsBig";


export default {
  name: "PropertyCardBig",
  props: {
    color: String,
    price: String,
    slogan: String,
    tag: null,
    baths: null,
    rooms: null,
    garage: null,
    area: null,
    img: String,
    inMap: Boolean,
    //!! ERBI !! change this, no default needed here. Its just a mock 
    location: {
      type: String,
      default: 'Lisboa'
    },
    cityAddress: {
      type: String,
      default: ''
    },
    reference: {
      type: String,
      default: ''
    },
    measure: {
      type: String,
      default: ''
    },
    dev_id: Number,
    isMobile:{
      type: Boolean,
      default: false
    },
    isListingView:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      reloadKeyImage: 0
    }
  },
  components: {
    PropertyCardTags,
    PropertyDetailsBig
  },
  methods:{
    errorImage(){
      //console.log("ERRO BIG")
      this.reloadKeyImage++
    }
  },
  computed:{
    getSlug(){
      if(this.$route.name == 'home')
        return 1

      

      return this.$route.params.slug
    },
    getDevId(){
      return this.dev_id
    }
  }
}
</script>