<template>
    <div class="lg:mt-32" v-if="selectedAgent != null">
         <div class="w-full h-screen-50 lg:mb-24 lg:mt-24">
            <div class="w-full h-full flex justify-center">
                <img class="mt-8 w-1/2 lg:w-1/6 h-full left-1/4 object-cover rounded-full border-14 border-black sm:h-32 sm:border-14 lg:w-1/6 lg:h-full lg:border-14" 
                    src="/img/sellwithus/sellwithus.jpg"
                >
            </div>
        </div>
        <div class="w-full mt-16 lg:mt-32">
            <div class="flex items-center w-full justify-center text-center font-blackMango text-lg uppercase tracking-widest font-black">
                {{ getAgentName }}
            </div>
            <div class="flex items-center w-full justify-center text-center font-blackMango text-md uppercase tracking-widest font-black">
                {{ getAgentPosition }}               
            </div>
            <div class="mt-4 lg:mt-8 flex flex-wrap items-center w-full justify-center text-center">
                <div class="w-3/4 lg:w-5/12 text-justify text-base leading-none">
                    <p class="text-darkgray tracking-widest mt-4"  style="white-space: pre-line">{{ getAgentDescription }}</p>
                    <button v-if="selectedAgent.id == '1'" @click="openWorkModal" class="font-medium text-sm mt-4 lg:mt-20 text-sm uppercase tracking-button flex items-center justify-center bg-black text-white p-4 relative w-full mx-auto pointer-events-auto">
                        <span>{{$t('about.LA.work_with_agent')}}</span>
                    </button>
                </div>
            </div>
        </div>
        
        

        

    </div>
</template>
<script>
export default {
    name: "AgentLA",
    props: ['selectedAgent'],
    methods:{
        openWorkModal(){
            this.$emit('open-modal')
        }
    },
    computed:{
        getAgentName(){
            return this.selectedAgent.first_name + ' ' + this.selectedAgent.last_name
        },
        getAgentPosition(){
            return this.$processLanguage(this.selectedAgent.role)
        },
        getAgentDescription(){
            return this.$processLanguage(this.selectedAgent.description)
        }
    }
}
</script>
