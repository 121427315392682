function processPrintLanguage(obj, l) {
    if(obj == undefined)
      return ''

    if(l == "pt")
      return obj.pt

    if(l == "en")
      return obj.en
  }
  
  export default processPrintLanguage