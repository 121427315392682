<template>
    <v-select
            :items="items"
            :item-text="itemText"
            :item-value="itemValue"
            :label="label"
            :multiple="multiple"
            :chips="chips"
            :hint="hint"
            :value="fillData"
            :persistent-hint="persistentHint"
            :small-chips="true"
            outlined
            v-model="fillData"
            @input="updateValue"
            @change="updateValue"
    ></v-select>
</template>

<script>
  export default {
    name: 'InputSelect',
    props: {
      items: {
        type: Array,
        default: null
      },
      itemText: {
        type: String,
        default: null
      },
      itemValue: {
        type: String,
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      chips: {
        type: Boolean,
        default: false
      },
      persistentHint: {
        type: Boolean,
        default: false
      },
      hint: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: 'Label'
      },
      fill: [Number, String],
    },

    computed: {
      fillData: {
        get() {
          return this.fill
        },
        set(value) {
          this.$emit('update:prop', value)
        }
      }
    },

    methods: {
      updateValue(e) {
        this.$emit('input', e)
      }
    }
  }
</script>