<template>
    <div class="mt-4 lg:mt-20 h-auto " :key="reloadVineyardsContact">
        <div class="h-full">
            <div class="w-full h-full">
                <div class="bg-vineyard-background bg-cover bg-center w-full">
                    <div class=" flex justify-center content-center">
                        <div class="rounded-2xl mt-16 my-16 glass-effect glass-effect-white glass-fallback w-11/12 lg:w-2/5">
                            <div class="flex justify-center uppercase mt-8">
                                <div class="w-1/2 mb-2 lg:mb-8">
                                    <inline-svg class="inset-x-0 m-auto" width="auto" height="auto" src="/svg/thisisla/vineyards.svg" />
                                </div>
                            </div>
                            <div class="uppercase text-center font-bold">
                                <p class="text-sm tracking-widest font-blackMango lg:text-base">{{ $t('about.VineyardsContact.coming_soon') }}</p>
                            </div>
                            <div class="justify-center flex flex-wrap content-center mt-2 lg:mt-4 mb-5 lg:mb-4">
                                <div class="border-2 w-4/5 text-center md:w-4/5">
                                    <div class="m-2 lg:m-8">
                                        <p class="uppercase tracking-widest mt-4 font-blackMango font-bold sm:text-sm lg:text-sm">{{$t('about.VineyardsContact.contact_form.title')}}</p>
                                        
                                        
                                        <iText id="name" name="name" class="mt-2 mt-4 text-black placeholder-black" :placeholder="$t('about.VineyardsContact.contact_form.name')"
                                            ref="name" type="text" v-model="thisIsLa.contact.name" />
                                        
                                        <iText id="email" name="email" class="mt-2 mt-4 text-black placeholder-black" :placeholder="$t('about.VineyardsContact.contact_form.email')"
                                            ref="email" type="text" v-model="thisIsLa.contact.email" />

                                        
                                        <div class="mt-4 md:mt-2 flex items-center">
                                            <div id="searchForDiv" class="flex items-center w-full border-b border-black focus:shadow-outline focus:border-opacity-100  border-opacity-25">
                                                <select id="searchForSelect"
                                                    class="bg-transparent w-full hover:bg-transparent text-base text-black placeholder-black appearance-none cursor-pointer pr-4 py--2 block h-full" 
                                                >                                            
                                                    <option disabled selected hidden>{{ this.$t('about.VineyardsContact.contact_form.iSearch') }}</option>
                                                    <option v-for="item in $t('about.VineyardsContact.iSearchFor')" :key="item">{{item}}</option>
                                                </select>
                                                <inline-svg fill="black" class="hidden md:block w-3 h-3 right-0 m-5 transform rotate-90" src="/svg/arrow/arrow.svg" />
                                            </div>
                                        </div>


                                        <div class="mt-4 md:mt-2 flex items-center">
                                            <div id="regionDiv" class="flex items-center w-full border-b border-black focus:shadow-outline focus:border-opacity-100  border-opacity-25">
                                                <select class="bg-transparent  w-full hover:bg-transparent text-base text-black placeholder-black appearance-none cursor-pointer md:pr-4 md:py-2 block h-full" id="regionSelect">                                            
                                                    <option disabled selected hidden>{{ this.$t('about.VineyardsContact.contact_form.region') }}</option>
                                                    <option v-for="item in $t('about.VineyardsContact.VineyardRegions')" :key="item">{{item}}</option>
                                                </select>
                                                <inline-svg fill="black" class="hidden md:block w-3 h-3 right-0 m-5 transform rotate-90" src="/svg/arrow/arrow.svg" />
                                            </div>
                                        </div>
                                        <div class="pb-2 text-left">
                                            <div id="check1Div">
                                                <iCheckbox class="mt-4 md:mt-10 h-auto" id="check1" name="terms" :text="$t('homepage.contact.inputs.agree')"
                                                    :privacy="$t('homepage.contact.inputs.privacy')" />
                                            </div>
                                            <div id="check2Div">
                                                <iCheckbox class="mt-2 h-auto" id="check2" name="terms" :text="$t('homepage.contact.inputs.communications')" />
                                            </div>
                                        </div>
                                        <div class="pb-2 text-left mt-8" v-show="formError">
                                            <p class="text-sm text-red-400">* {{ $t('General.forms.error') }}</p>
                                        </div>
                                        <button @click="processForm()" class="btn-submit absolute font-medium text-sm mt-4 -m-2 lg:m-0 lg:mt-20 text-sm uppercase tracking-button flex items-center justify-center bg-black text-white p-4 relative w-full lg:mx-auto">
                                            <span>{{$t('buttons.submit')}}</span>
                                            <div class="absolute right-0 flex justify-center w-1/3">
                                                <inline-svg height="15" src="/svg/arrow/arrowGradient.svg" />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    @media (max-width:960px){
        .btn-submit{
            width: calc(100% + 1rem);
            border-radius:0;
        }
    }
</style>
<script>
import iText from '@/components/contact/types/iText';
import iCheckbox from '@/components/contact/types/iCheckbox';

// Execution code
import mixin from '@/models/general/GeneralModel'

export default {
    name: "AboutVineyardsContact",
    components:{
        iCheckbox,
        iText
    },
    mixins: [mixin],
    data(){
        return{
            reloadVineyardsContact: 0,
            thisIsLa:{
                contact: {
                    name: "",
                    email: "",
                    iSearchFor: "",
                    region: "",
                    receive_comm: ""
                }
            },
            formError: false
        }
    },
    methods:{
        onSelected(i, id){
            switch(id){
                case 'name':
                    this.sellWithUsForm.property_info.vending_type = i.name
                    break;
            }
        },
        async processForm(){
            const name = document.getElementById('name').value;
            this.thisIsLa.contact.name = name;
            this.thisIsLa.contact.email = document.getElementById('email').value
            this.thisIsLa.contact.iSearchFor = document.getElementById('searchForSelect').value
            this.thisIsLa.contact.region = document.getElementById('regionSelect').value
            this.thisIsLa.contact.receive_comm = document.getElementById('check2').checked
            
            
            if(this.validateForm())
                return
            
            this.thisIsLa.contact.email = ''
            this.reloadVineyardsContact++
            await this.vineyardContact(this.thisIsLa)
            this.$emit('thankYouMessage', name );

        },
        validateForm(){
            let invalid = false
            if(this.thisIsLa.contact.name == ""){
                document.getElementById('name').style="border-color: red;";
                invalid = true
            }else document.getElementById('name').style="";

            if(this.thisIsLa.contact.email == "" || this.thisIsLa.contact.email.indexOf('@') == -1){
                document.getElementById('email').style="border-color: red;";
                invalid = true
            }else document.getElementById('email').style="";
      
            
            if(this.thisIsLa.contact.iSearchFor == "I am looking to"){
                document.getElementById('searchForDiv').style="border-color: red;";
                invalid = true
            }else document.getElementById('searchForDiv').style="";

            if(this.thisIsLa.contact.region == "Region"){
                document.getElementById('regionDiv').style="border-color: red;";
                invalid = true
            }else document.getElementById('regionDiv').style="";

            if(!document.getElementById('check1').checked){
                document.getElementById('check1Div').style="color: red;";
                invalid = true
            }else document.getElementById('check1Div').style="";

            this.formError = invalid

            return invalid
        }
    }
}
</script>