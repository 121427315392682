function processLanguage(obj) {
  if(obj == undefined)
    return ''
  let l = this.$i18n.locale
  if(l == "pt")
    return obj.pt
  if(l == "en")
    return obj.en
}

export default processLanguage