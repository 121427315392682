import * as types from '@/store/mutation-types'
import api from '@/services/api/condominiums/condominiums'
import { handleError } from '@/utils/utils.js'
//import router from '@/router'

const getters = {
  condominiums: (state) => state.condominiums,
}

const actions = {
  getCondominiums({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCondominiums(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CONDOMINIUMS, response.data.condominiums)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getHomepageCondominiums({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getHomepageCondominiums(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CONDOMINIUMS, response.data.condominiums)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },  
  getCondominiumBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCondominiumBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CONDOMINIUM_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getCondominiumMediaBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCondominiumMediaBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.MEDIA_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }, 
  getCondominiumAttachmentBlock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getCondominiumAttachmentBlock(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ATTACHMENT_BLOCK, response.data.block)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }, 
}

const mutations = {
  [types.CONDOMINIUMS](state, condominiums) {
    state.condominiums = condominiums
  },
  [types.CONDOMINIUM_BLOCK](state, block) {
    state.block = block
  },  
  [types.MEDIA_BLOCK](state, block) {
    state.medias = block
  },
  [types.ATTACHMENT_BLOCK](state, block) {
    state.attachments = block
  },
}

const state = {
  condominiums: [],
  block: [],
  medias: [],
  attachments: [],
}

export default {
  state,
  getters,
  actions,
  mutations
}