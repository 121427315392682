import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`General Model action called.`)
  },

  methods: {
    ...mapActions(['saveNewsletterEmail', 'getWhoWeAreDescription', 'getAllAgents', 'getServiceDescription', 'getAllMapMarkersById', 'workWitheMeContactMessage', 'vineyardContactMessage', 'getServiceDescriptionFront', 'getAllServices', 'sellWithUsRequest', 'saveHomeMessage', 'getAllMapMarkers', 'getMediaBlock', 'getAttachmentBlock', 'bookVisitMessage']),

    async saveMessage(data){
        try{
            // SAVE MARKERS
            await this.saveHomeMessage({'data': data.data, 'block': data.block})
          }catch(error){
            this.dataTableLoading = false
          }
      },
      async newsletterSaving(data){
        try{
          // SAVE MARKERS
          return await this.saveNewsletterEmail({'address': data.address, 'block': data.target})
        }catch(error){
          return this.dataTableLoading = false
        }
      },
      async bookVisit(data){
        try{
            // SAVE MARKERS
            return await this.bookVisitMessage({'data': data})
          }catch(error){
            return this.dataTableLoading = false
          }
      },
      async getMapMarkers(){
        try{
            // SAVE MARKERS
            await this.getAllMapMarkers()
          }catch(error){
            this.dataTableLoading = false
          }
      },
      async getMapMarkersById(id){
        try{
            // SAVE MARKERS
            await this.getAllMapMarkersById(id)
          }catch(error){
            this.dataTableLoading = false
          }
      },
      async sellWithUsReq(data){
        try{
          return await this.sellWithUsRequest({'data': data.data, 'block': data.block})
        }catch(error){
          return this.dataTableLoading = false
        }
      },
      async getServices(){
        try{
          await this.getAllServices()
        }catch(error){
          this.dataTableLoading = false
        }
      },
      async getServiceDescriptionFrontPage(){
        try{
          await this.getServiceDescriptionFront()
        }catch(error){
          this.dataTableLoading = false
        }
      },
      async vineyardContact(data){
        try{
            // SAVE MARKERS
            await this.vineyardContactMessage({'data': data})
          }catch(error){
            this.dataTableLoading = false
          }
      },
      async workWitheMeContact(data){
        try{
          // SAVE MARKERS
          return await this.workWitheMeContactMessage({'data': data})
        }catch(error){
          this.dataTableLoading = false
          return false;
        }
      }


      
  }
}

export default mixin
