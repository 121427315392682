<template>
    <div v-if="length" class="collection-project-img w-full h-full relative overflow-hidden">
         <!-- image slider -->
        <swiper :options="swiperOption" @slideChange="changeNavigation" 
            class="swiper h-full w-full flex"
            ref="swiperC">
            <swiper-slide :key="i" v-for="(item, i) in listMedias">

                <img :key="reloadKeyImage" class="object-cover w-full h-full"                 
                    v-lazy="$mediaFile('properties', item.property_id , item.file)" 
                    alt="project"
                    @error="errorImage">

            </swiper-slide>
        </swiper>

        <!-- navigation slider-->
        <div class="font-bold font-blackMango flex space-x-4 text-md text-white p-4 bg-black absolute top-0 left-0 z-10">
            <button class="btn w-100 text-center" @click="toPrevious()">
                <inline-svg class="transform rotate-180" height="15" fill="white" src="/svg/arrow/arrow.svg" />
            </button> 
            <p class="tracking-widest">{{this.index}}/{{this.length}}</p>
             <button class="btn w-100 text-center" @click="toNext()">
                <inline-svg  height="15" fill="white" src="/svg/arrow/arrow.svg" />
            </button> 
        </div>
    </div>
</template>
<script>
   import {
       Swiper,
       SwiperSlide
   } from 'vue-awesome-swiper';
   import 'swiper/swiper-bundle.css';
  import mixinProperty from '@/models/properties/PropertiesModel';
  import api from '@/services/api/properties/properties';

   export default {
        name: "CollectionProjectImage",
       components: {
           Swiper,
           SwiperSlide,
       },
       props: ["project"],
       mixins: [mixinProperty],
       data() {
           return {
               mediaBlock: "homepageMedia",
               projectC: this.project,
               index: 1,
               length: null,
               slidesPerView: 1,
               reloadKeyImage: 0,
               swiperOption: {
                   loop: false,
               },
                media: undefined
           }
       },
        async created(){
            const res = await api.getPropertyBlock({ id: this.project.id, block: this.mediaBlock })
            this.media = res.data.block;
            this.length = res.data.block.length
        },
       methods: {
           //next slide
           toNext() {
               this.$refs.swiperC.$swiper.slideNext();
           },
           //previous slide
           toPrevious() {
               this.$refs.swiperC.$swiper.slidePrev();
           },
           //change index in navigation
           changeNavigation() {
                this.index = (this.$refs.swiperC.$swiper.activeIndex) + 1;
            },
            errorImage(){
                //console.log("COLLECTION IMAGE ERROR")
                this.reloadKeyImage++
            }
       },
       computed:{
          listMedias(){
            let ordered = this.media
            ordered.sort((a, b) => {
                return a.ordering - b.ordering
            })

            ordered.sort((a, b) => {
                return b.cover - a.cover
            })
            
            //console.log("TESTE")
            //console.log(ordered)
            return ordered;
          }
       }
   }
</script>