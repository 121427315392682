import * as types from '@/store/mutation-types'
import api from '@/services/api/keys/keyItems'
import { handleError } from '@/utils/utils.js'


const getters = {
  keys: (state) => state.keys,
  totalKeys: (state) => state.totalKeys
}

const actions = {
  getKeyItems({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getKeyItems(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('key', { k: payload.extra, data: response.data.keys })
            commit(types.KEYS, response.data.keys)
            commit(types.TOTAL_KEYS, response.data.total)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  }
}

const mutations = {
  [types.KEYS](state, keys) {
    state.keys = keys
  },
  [types.TOTAL_KEYS](state, value) {
    state.totalKeys = value
  },
  key(state, data) {
    state.key[data.k] = data
  }
}

const state = {
  keys: [],
  totalKeys: 0,
  key: {
    features: { data: [] },
    types: { data: [] },
    listing: { data: [] },
    lifestyle: { data: [] }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
