<template>
  <div @click="generateLink(id)">
    <div class="relative text-lg container">
      <!-- image -->
      <div :class="sizeImg"
        class="overflow-hidden rounded-lg hover:border-bronze border-2 transition-all duration-300 border-transparent w-full relative">
        <img :key="reloadKeyImage"
          class="hover:scale-110 origin-center transition-all transform duration-500 ease-in-out w-full h-full object-cover"
          v-lazy="img" @error="errorImage">

        <!-- tag -->
        <div class="absolute w-full p-3 z-10">
          <PropertyCardTags v-if="tag" :tag="tag" size="small" />
        </div>
        <!-- price -->
        <div
          class="absolute text-sm left-1 bottom-1 z-10 flex justify-center items-center rounded-md md:rounded-lg p-0 h-5 md:h-auto px-2 md:py-1 md:px-4 glass-effect glass-effect-white glass-fallback">
          <p class="text-darkgray text-base font-semibold">{{$currency(price)}}</p>
        </div>
      </div>
      <div class="mt-2">
        <!-- house details -->
        <div class="flex flex-initial items-end space-x-2 justify-center">
          <PropertyDetailsSmall :number='rooms' img="/svg/houseDetails/rooms.svg" :color="color" />
          <PropertyDetailsSmall :number='baths' img="/svg/houseDetails/baths.svg" :color="color" />
          <PropertyDetailsSmall :number='garage' img="/svg/houseDetails/garage.svg" :color="color" />
          
          <PropertyDetailsSmall :number='area' :extra='getAreaType()' img="/svg/houseDetails/area.svg" :color="color" />
          
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.container {
  cursor: pointer;
}
</style>
<script>
import PropertyCardTags from "@/components/listings/cards/PropertyCardTags";
import PropertyDetailsSmall from "@/components/listings/cards/PropertyDetailsSmall";


export default {
  name: "PropertyCardSmall",
 props: {
    id: Number,
    sizeImg: String,
    color: String,
    price: String,
    slogan: String,
    tag: String,
    baths: null,
    rooms: null,
    garage: null,
    area: null,
    img: String,
    measure: {
      type: String,
      default:''
    },
    dev_id: Number
  },
    components: {
    PropertyCardTags,
    PropertyDetailsSmall
  },
  data(){
    return{
      reloadKeyImage: 0
    }
  },
  methods:{
    generateLink(id){
        this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
    },
    getAreaType(){
      if(this.$i18n.locale == 'en')
        return ' sq.ft'
      else return 'm2'
    },
    // force the image to load
    errorImage(){
      this.reloadKeyImage++
    }
  },
  computed:{
    getSlug(){
      if(this.$route.name == 'home')
        return 1

      return this.$route.params.slug
    },
    getDevId(){
      return this.dev_id
    }
  }
}
</script>