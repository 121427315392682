import axios from 'axios'
//import { getFormDataMultidimensional } from '@/utils/utils'

export default {
  getProperties(params) {
    return axios.get('/front/properties/All', {
      params
    })
  },
  homepageGetProperties(params) {
    return axios.get('/front/properties', {
      params
    })
  },
  getHomepageCollectionPrivee(params) {
    return axios.get('/front/collection', {
      params
    })
  },  
  getPropertyBlock(params) {
    return axios.get(`front/property/${params.id}/${params.block}`, {
      params
    })
  }, 
  getMediaBlock(params) {
    return axios.get(`/property/${params.id}/${params.block}`, {
      params
    })
  }, 
  getAttachmentBlock(params) {
    return axios.get(`/property/${params.id}/${params.block}`, {
      params
    })
  },
  getPropertiesFromCondominium(params) {
    return axios.get(`/properties/from_condominium/${params.id}`, {
      params
    })
  },

  
}
