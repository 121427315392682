<template>
    <transition name="modal-fade">
      <div class="thankyouModal">
        <div class="relative h-full w-full">
          <button class="close absolute right-4 top-4 z-40 h-auto" @click="close">
                <inline-svg class="transform origin-center rotate-45 w-4 h-4 md:w-2 md:h-2 md:w-2 md:h-2 lg:w-5 lg:h-5" fill="black"
                  src="/svg/plus.svg" />
              </button>
            <img src="/img/cover/cover3.jpg" class="h-full w-full object-cover thankyoumodal-img">
            <inline-svg v-if="false" width="5rem" height="auto" style="filter:invert(1);" class="hidden md:block absolute left-4 bottom-4 z-40 h-auto" src="/svg/logos/logo_agency_white.svg" />
            <img class="w-1/4 hidden md:block absolute left-0 z-40 h-auto" style="margin-left: -7%; margin-top: -13%;" src="/new_logos/2022-LA-Agency-Icon_Gold.png">


            <img class="md:hidden w-1/2 absolute bottom-0 z-40 h-auto" style="transform:scale(200%) left:-6%" src="/new_logos/2022-LA-Agency-Icon_Gold.png" />
        </div>
        <p class="font-blackMango text-lg text-center md:text-left w-9/12 md:w-auto title">{{$t('ThankYouModal.thanks')}}</p>
        <p class="font-euclid text-md font-medium md:font-normal subtitle">{{$t('ThankYouModal.response')}}</p>
        <span class="font-euclid text-sm md:text-md subtitle w-9/12 text-center md:mt-4">{{$t('ThankYouModal.professionals')}}</span>
        <button @click="close"
          class="mt-3 mb-3 md:mb-0 bg-black  hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white py-2 md:py-5 px-4 md:px-10 md:p-16">
          <span>{{ $t('ThankYouModal.back') }}</span>
      </button>
      </div>
    </transition>
</template>
<style scoped>
  .thankyouModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thankyoumodal-img{
    max-height: clamp(100px, 40vh, 400px);
    display: block;
    border-top-right-radius:23px;
    border-top-left-radius:23px;
    object-position: bottom;
  }
  @media (min-width:720px){
    .title{
      font-weight: bold;
      margin-top: 3rem;
    }
    p.subtitle{
      margin-top: .5rem;
      margin-bottom: 1rem;
    }
    span.subtitle{
      margin-bottom: 2rem;
      color: var(--color-gray);
    }
  }
  @media (max-width:719px){
    .title{
      font-weight: bold;
      margin-top: 1rem;
    }
    p.subtitle{
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
    span.subtitle{
      color: var(--color-gray);
    }
  }
  
  .paper-icon{
    width: 4rem;
    height: 4rem;
    fill:var(--color-bronze)
  }
</style>
<script>
  export default {
    name: 'ThankYouModal',
    methods:{
      close() {
        this.$emit('close');
      }
    }
  };
</script>