<template>
    <div :class="containerClass" class="flex">
        <inline-svg v-if="map" class="fill-current w-2 h-2 xs:w-2 xs:h-2 md:w-3 md:h-3 lg:w-5 lg:h-5" :src="img" />
        <inline-svg v-else-if="homepage" class="fill-current w-4 h-4 xs:w-2 xs:h-2 md:w-4 md:h-4 lg:w-7 lg:h-7" :src="img" />
        <inline-svg v-else class="fill-current w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" :src="img" :class="svgClass"/>
        <div class="md:ml-1 font-medium leading-none bottom-0 pt-1 md:pt-3 lg:pt-4 pro-details" :class="[!homepage && !map ? 'text-baselg' : 'text-base', svgClass && 'listing-description' ]">
          {{ number }} {{ measure }}
        </div>
    </div>
</template>
<style scoped>
.pro-details{
  white-space: nowrap;
}
@media (min-width:960px){
  .listing-icons{
    width: auto;
    height: 100%;
  }
  .listing-description{
    padding: 0;
    padding-top: .4rem;
    font-size: .9rem;
  }
}
</style>
<script>
    export default {
        name: "PropertyDetailsBig",
        props: {
            number: null,
            img: String,
            color: String,
            homepage: Boolean,
            map:Boolean,
            svgClass: String,
            measure:{
                type: String,
                default: ''
            }
        },
        computed:{
          containerClass(){
            const style = " text-" + this.color;
            return style + (this.map ? ' items-end' : ' items-center');
          }
        }
    }
</script>