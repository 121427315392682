function mediaFile(path, id, file) {
  if(file == undefined)
    return ''
  if (file.length > 0) {
    if (file.startsWith('http')) {
      return file
    }
    return `${process.env.VUE_APP_MEDIA_URL}/${path}/${id}/${file}`
  }
}

export default mediaFile