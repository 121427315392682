<template>
    <div class="w-full">
        <div class="ml-8 mr-8 lg:mx-20">
            <p class="text-price font-understock lg:text-lg"> {{ $t('about.LA.work_with_me.title') }} {{ $t('about.LA.agent_founder') }}</p>
            <p class="text-sm mx-auto mt-5 lg:text-md description-text">{{ $t('about.LA.work_with_me.description') }}</p>
            <p class="text-sm mx-auto mt-0 lg:text-md description-text">{{ $t('about.LA.work_with_me.description2') }}</p>
            <p class="text-sm mx-auto mt-0 lg:text-md description-text">{{ $t('about.LA.work_with_me.description3') }}</p>

            <form @submit.prevent="processWorkWithMeForm" class="space-y-2 w-full md:mt-4 mt-2 lg:mt-10" id="messageForm">
                <div class="lg:flex w-full lg:space-x-3 lg:space-x-8 mb-10">
                    <iText id="firstName" name="firstName" :placeholder="$t('about.LA.work_with_me.name_placeholder')"
                        ref="firstName" type="text" v-model="workWithMe.firstName" />                 
                </div>

                <div class="lg:flex w-full lg:space-x-3 lg:space-x-8 mb-10">
                    <iText id="emailWorkWithMe" name="emailWorkWithMe" :placeholder="$t('about.LA.work_with_me.email')" ref="email"
                        type="email" v-model="workWithMe.email" />
                    <iText id="phone" name="phone" :placeholder="$t('homepage.contact.inputs.phone')" ref="phone" type="tel"
                        v-model="workWithMe.phone" />
                </div>

                <iTextarea class="mb-10" name="message" :placeholder="$t('about.LA.work_with_me.textArea')" ref="message"
                    v-model="workWithMe.message" id="message" />

                <div class="flex w-full lg:space-x-3 lg:space-x-8 mt-8 mb-24">
                    <inline-svg fill="black" class="block right-0 mt-8 mb-8 mr-2 lg:-mr-6" src="/svg/cloudUpload.svg" />
                    <button class="btn btn-info mt-8 mb-8 font-bold text-baselg" @click="onPickFile">{{ getUploadFileName }}</button>
                    <input
                        type="file"
                        style="display: none;"
                        ref="fileInput"
                        accept="image/*, application/pdf" 
                        @change="onFilePicked"
                        />
                </div>

                <div class="pb-2 mt-8">
                    <div id="check1DivW">
                        <iCheckbox class="h-auto" id="check1W" name="terms" :text="$t('homepage.contact.inputs.agree')"
                            :privacy="$t('homepage.contact.inputs.privacy')" />
                    </div>
                    <div id="check2DivW">
                        <iCheckbox class="h-auto" id="check2W" name="terms" :text="$t('homepage.contact.inputs.communications')" />
                    </div>
                </div>
                <div class="pb-2" v-show="formError">
                    <p class="text-sm text-red-400">* {{ $t('General.forms.error') }}</p>
                </div>
                <div class="flex space-x-4 md:space-x-8 justify-center items-center mt-6">
                    <button 
                        form="messageForm"
                        type="submit"
                        class="mt-3 bg-black hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-full py-2 md:py-5">
                        <span>{{ $t('buttons.sendMessage') }}</span>
                        <div class="absolute right-3 flex justify-center">
                            <inline-svg fill="white" height="15" src="/svg/arrow/arrowGradient.svg" />
                        </div>
                    </button>
                </div>
            </form>
            <div class="md:mt-4 mt-2">
                <div class="flex space-x-4 md:space-x-8 justify-center items-center mt-6">
                    <a href='https://wa.me/351966764147' class="transform transition duration-500 hover:scale-110"
                        target="_blank">
                        <inline-svg fill="black" class="md:h-9 md:w-9 w-6 h-6" src="/svg/socials/whatsapp.svg" />
                    </a>

                    <a href='tel:Zoom351966764147' class="transform transition duration-500 hover:scale-110"
                        target="_blank">
                        <inline-svg fill="black" class="md:h-9 md:w-9 w-6 h-6" src="/svg/socials/zoom.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.description-text{
  font-weight: 400;
}
</style>
<script>
import iText from '@/components/contact/types/iText';
import iTextarea from '@/components/contact/types/iTextarea';
import iCheckbox from '@/components/contact/types/iCheckbox';

// Execution code
import mixin from '@/models/general/GeneralModel'

    export default {
    name: "WorkWithMe",
    mixins: [mixin],
    components: {
        iText,
        iTextarea,
        iCheckbox,
    },
    data() {
        return {
          showThankYou: false,
            workWithMe: {
                name: "",
                email: "",
                phone: "",
                message: "",
                receive_comm: "",
                file_upload: null
            },
            formError: false,
            uploadFileName:{
                label:{
                    en: "Upload File (optional)",
                    pt: "Carregar ficheiro (opcional)"
                },
                fileUploaded: ''
            }
        }
    },
    computed:{
        getUploadFileName(){
            if(this.uploadFileName.fileUploaded == ''){
                return this.$processLanguage(this.uploadFileName.label)
            }

            return this.uploadFileName.fileUploaded
        }
    },
    methods:{
        onPickFile () {
            this.$refs.fileInput.click()
        },
        onFilePicked (event) {
            const files = event.target.files

            this.workWithMe.file_upload = files[0]
            
            this.uploadFileName.fileUploaded = files[0].name
        },
        async processWorkWithMeForm(){
            const name = document.getElementById('firstName').value;
            this.workWithMe.name = name;
            this.workWithMe.email = document.getElementById('emailWorkWithMe').value
            this.workWithMe.phone = document.getElementById('phone').value
            this.workWithMe.message = document.getElementById('message').value
            this.workWithMe.receive_comm = document.getElementById('check2W').checked

            if(this.validateWorkWithMeForm())
                return
            
            await this.workWitheMeContact(this.workWithMe);
            this.$emit('thankYouMessage', name );

        },
        validateWorkWithMeForm(){
            let invalid = false
            if(this.workWithMe.name == ""){
                document.getElementById('firstName').style="border-color: red;";
                invalid = true
            }else document.getElementById('firstName').style="";

            if(this.workWithMe.email == "" || this.workWithMe.email.indexOf('@') == -1){
                document.getElementById('emailWorkWithMe').style="border-color: red;";
                invalid = true
            }else document.getElementById('emailWorkWithMe').style="";
      
            if(this.workWithMe.message == ""){
                document.getElementById('message').style="border-color: red;";
                invalid = true
            }else document.getElementById('message').style="";

            if(!document.getElementById('check1W').checked){
                document.getElementById('check1DivW').style="color: red;";
                invalid = true
            }else document.getElementById('check1DivW').style="";
            
            this.formError = invalid

            return invalid
        }
    }
}
</script>