<template>
  <div>
    <ul class="text-md uppercase text-gray cursor-pointer mb-2 leading-4">
        <li class="cursor-pointer">
          {{ $i18n.locale }}
        </li>
        <li class="cursor-pointer" v-for="locale in localeOptions" :key="locale" @click="switchLocale(locale)">
          {{locale}}
        </li>
    </ul>
  </div>
</template>
<script>
import * as __COOKIES from '@/store/cookies/cookies'

export default {
  name: 'LocaleSwitcher',
  props:{
    fillColor:{
      type: String,
      default: 'black'
    },
    toCenter:{
      type: Boolean,
      default: false
    },
    verticalDrop: {
      type: Boolean,
      default: false
    }
  },
  created(){
    // Caso o user ja tenha mudado a lingua uma vez, entao vamos ao cookie e atualizamos
    let lng = this.$cookies.get(__COOKIES.LANGUAGE_SAVER)
    
    if(lng != 'en'){
        if(lng != null)
          this.switchLocale(lng)
    }
  },
  computed:  {
    getToCenter(){
      return this.toCenter;
    },
    localeOptions(){
      return this.locales.filter(l => l !== this.$i18n.locale);
    }

  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale} }) 
        this.$router.push(to.location) 
        this.languages = !this.languages;
        this.$cookies.set(__COOKIES.LANGUAGE_SAVER, locale, '1y')
      }
    },
    showLanguages(){
      this.languages = !this.languages
    }
  },
  data() {
    return {
      languages: false,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
    }
  }
}
</script>