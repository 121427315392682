function cutText(lastChar, max, mystring) {
  // Check if the word is just one word
  // EX: LAPA
  // Splitted: LAPA
  // Splitter is equal to entire string
  if(mystring == null || mystring == undefined)
    return ''

  let splitted = mystring.split(' ')
  if(splitted[0] == mystring)
    return mystring

  let originalString = mystring
  if(mystring){
    for (let i = max; i < mystring.length; i++) {
      if(mystring[i] == ' ' || mystring[i] == '.' || mystring[i] == '!' || mystring[i] == '?'){
        lastChar = i
        break
      }
    }
    if(mystring.slice(0, lastChar) == originalString)
      return originalString

    return mystring.slice(0, lastChar) + ' (...)'
  }
  else return '';
}

export default cutText